import css from '../sass/registerqr.module.scss'

const saveUrl = 'https://pay.google.com/gp/v/save/eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJldmVudC10aWNrZXQtdGVzdEBldmVudC10aWNrZXQtdGVzdC5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsImF1ZCI6Imdvb2dsZSIsIm9yaWdpbnMiOltdLCJ0eXAiOiJzYXZldG93YWxsZXQiLCJwYXlsb2FkIjp7ImdlbmVyaWNPYmplY3RzIjpbeyJpZCI6IjMzODgwMDAwMDAwMjIzMDEzMjIudWNsYWVhYXBwZGV2X2dtYWlsLmNvbSIsImNsYXNzSWQiOiIzMzg4MDAwMDAwMDIyMzAxMzIyLmNvZGVsYWJfY2xhc3MiLCJnZW5lcmljVHlwZSI6IkdFTkVSSUNfVFlQRV9VTlNQRUNJRklFRCIsImhleEJhY2tncm91bmRDb2xvciI6IiM0Mjg1ZjQiLCJsb2dvIjp7InNvdXJjZVVyaSI6eyJ1cmkiOiJodHRwczovL3N0b3JhZ2UuZ29vZ2xlYXBpcy5jb20vd2FsbGV0LWxhYi10b29scy1jb2RlbGFiLWFydGlmYWN0cy1wdWJsaWMvcGFzc19nb29nbGVfbG9nby5qcGcifX0sImNhcmRUaXRsZSI6eyJkZWZhdWx0VmFsdWUiOnsibGFuZ3VhZ2UiOiJlbiIsInZhbHVlIjoiR29vZ2xlIEkvTyAnMjIifX0sInN1YmhlYWRlciI6eyJkZWZhdWx0VmFsdWUiOnsibGFuZ3VhZ2UiOiJlbiIsInZhbHVlIjoiQXR0ZW5kZWUifX0sImhlYWRlciI6eyJkZWZhdWx0VmFsdWUiOnsibGFuZ3VhZ2UiOiJlbiIsInZhbHVlIjoiQWxleCBNY0phY29icyJ9fSwiYmFyY29kZSI6eyJ0eXBlIjoiUVJfQ09ERSIsInZhbHVlIjoiMzM4ODAwMDAwMDAyMjMwMTMyMi51Y2xhZWFhcHBkZXZfZ21haWwuY29tIn0sImhlcm9JbWFnZSI6eyJzb3VyY2VVcmkiOnsidXJpIjoiaHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL3dhbGxldC1sYWItdG9vbHMtY29kZWxhYi1hcnRpZmFjdHMtcHVibGljL2dvb2dsZS1pby1oZXJvLWRlbW8tb25seS5qcGcifX0sInRleHRNb2R1bGVzRGF0YSI6W3siaGVhZGVyIjoiUE9JTlRTIiwiYm9keSI6IjEyMzQiLCJpZCI6InBvaW50cyJ9LHsiaGVhZGVyIjoiQ09OVEFDVFMiLCJib2R5IjoiMjAiLCJpZCI6ImNvbnRhY3RzIn1dfV19LCJpYXQiOjE3MTA4MDUyODd9.5bGKqtnMPNosQY1z0x-yW9MwUEVZPoqItw7O8SNigoCvS0VsRGUdaeYY8kEKCGh6hFdv4nSKJsI3scAlqq4rZ56FaGzFyiag66CxxThe49i0cAV_JBtXYacyj6HEJS1DOH-edm_y3mzhSTnEoSfk_WL0A3ijcNqc70m5MFCcmN_5q8iVUDjUmbaCQaPBr7FGiiJqZbwJwnL8KpyfFeEJTZh4xQNwvwvHvu42VOQ3fQAb0p4X883TRhon7ugOP5eCWL2slc0uV1aMdNLG0RbI-lLgriOzFP7tAsR69GHAcDxlXFry6TS4Pm0ZDuZrbmkt4zcvaBOtfpW7fUwz-4MCzw'

const saveNikiUrl = 'https://pay.google.com/gp/v/save/eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJldmVudC10aWNrZXQtdGVzdEBldmVudC10aWNrZXQtdGVzdC5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsImF1ZCI6Imdvb2dsZSIsIm9yaWdpbnMiOltdLCJ0eXAiOiJzYXZldG93YWxsZXQiLCJwYXlsb2FkIjp7ImdlbmVyaWNPYmplY3RzIjpbeyJpZCI6IjMzODgwMDAwMDAwMjIzMDEzMjIubmZkZW5uaXN1Y19nbWFpbC5jb20iLCJjbGFzc0lkIjoiMzM4ODAwMDAwMDAyMjMwMTMyMi5jb2RlbGFiX2NsYXNzIiwiZ2VuZXJpY1R5cGUiOiJHRU5FUklDX1RZUEVfVU5TUEVDSUZJRUQiLCJoZXhCYWNrZ3JvdW5kQ29sb3IiOiIjNDI4NWY0IiwibG9nbyI6eyJzb3VyY2VVcmkiOnsidXJpIjoiaHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL3dhbGxldC1sYWItdG9vbHMtY29kZWxhYi1hcnRpZmFjdHMtcHVibGljL3Bhc3NfZ29vZ2xlX2xvZ28uanBnIn19LCJjYXJkVGl0bGUiOnsiZGVmYXVsdFZhbHVlIjp7Imxhbmd1YWdlIjoiZW4iLCJ2YWx1ZSI6Ikdvb2dsZSBJL08gJzIyIn19LCJzdWJoZWFkZXIiOnsiZGVmYXVsdFZhbHVlIjp7Imxhbmd1YWdlIjoiZW4iLCJ2YWx1ZSI6IkF0dGVuZGVlIn19LCJoZWFkZXIiOnsiZGVmYXVsdFZhbHVlIjp7Imxhbmd1YWdlIjoiZW4iLCJ2YWx1ZSI6IkFsZXggTWNKYWNvYnMifX0sImJhcmNvZGUiOnsidHlwZSI6IlFSX0NPREUiLCJ2YWx1ZSI6IjMzODgwMDAwMDAwMjIzMDEzMjIubmZkZW5uaXN1Y19nbWFpbC5jb20ifSwiaGVyb0ltYWdlIjp7InNvdXJjZVVyaSI6eyJ1cmkiOiJodHRwczovL3N0b3JhZ2UuZ29vZ2xlYXBpcy5jb20vd2FsbGV0LWxhYi10b29scy1jb2RlbGFiLWFydGlmYWN0cy1wdWJsaWMvZ29vZ2xlLWlvLWhlcm8tZGVtby1vbmx5LmpwZyJ9fSwidGV4dE1vZHVsZXNEYXRhIjpbeyJoZWFkZXIiOiJQT0lOVFMiLCJib2R5IjoiMTIzNCIsImlkIjoicG9pbnRzIn0seyJoZWFkZXIiOiJDT05UQUNUUyIsImJvZHkiOiIyMCIsImlkIjoiY29udGFjdHMifV19XX0sImlhdCI6MTcxMTM5MzU1OX0.YrDCmUjTJISv0KqeJ8TgLQfFv9JR03yzO93msR5DVV0uSgIwG6c6NJ8IYhEkgu3dPLbpsQHMPeaKr0UxM9NgNm9dsjPQfHTU9kAlYG19WZlKtS290FGA4-GdARTzAaHH-r_x1KBGgueoTUsmJIbS6PSJfv0zJI7htTqzmS8axz3b1QzkM6hVWIq4w3qT_mVhBuYyqsxLHjNz1zPJuiJe1G_IlkmKrKuip-tTq8lzr_8e-bSP2zqpYjOB5KyRzBibXV7jfr2ZTyJjZb5aCz8H4cM99edMkLm3AkmxkMXD_UUIOLjFUqyGhA7S5hULPR9dPoYZhwOWRKWfODvVMLFEvg'

export default function GoogleButton( {auction} ) {
  
  return (
    <a href={saveNikiUrl}>
      <img className={`${css['qr-confirm-wallet-button']} ${css['qr-confirm-wallet-button-google']}`} src='https://developers.google.com/static/wallet/images/branding/temp-wallet-condensed.png' alt='Add to Google Wallet' />
    </a>
  )
}