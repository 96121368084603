import { useState} from 'react'
import { useSelector } from "react-redux"

import { history } from 'services/history.js'
import { showLoading } from 'services/util'

import { submitCode, signUpUserForTextAlerts } from './verifyUtils'
import { getAuctionUser } from 'sites/auctions/redux/selectors'

import css from '../sass/flow.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function PhoneVerificationCode(props) {
  console.log(props)
  const [verificationCode, setVerificationCode] = useState()
  const [mobileNumber, setMobileNumber] = useState(props.location.mobileNumber)

  const auctionUser = useSelector(getAuctionUser)

  console.log({mobileNumber})

  const submitVerification = async () => {

    showLoading(true)

    try {
      const codeResponse = await submitCode(mobileNumber, verificationCode)
      // const codeResponse = 'SUCCESS'
      console.log({codeResponse})

      if (codeResponse === 'SUCCESS') {
        const signUpResponse = await signUpUserForTextAlerts(props.location.auction, auctionUser, mobileNumber)
        console.log({signUpResponse})
        if (signUpResponse.success) {
          history.push({ pathname: '/auctionhome', search: '?auctionId=' + props.location.auction.AuctionCode })
        }
        else {
          // do error behavior
          console.log({codeResponse, signUpResponse})
        }
      }
      else {
        // do error behavior
        console.log({codeResponse})
      }

      showLoading(false)
    }
    catch (e) {
      showLoading(false)
      console.log(e)
    }
  }

  return (
    <div className={css['flow-container-w-margin']}>
      <h2 className={css['centered']}>Enter Verification Code</h2>
      <div className={css['flow-inputs']}>
        <label>Verification Code</label>
        <input type='text' value={verificationCode} onChange={(e) => setVerificationCode(e.target.value.trim())} />
      </div>

      <div className={css['flow-button-container']}>
        <button 
          onClick ={() => submitVerification()}
          className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>Complete Account Set-Up</button>

        <button 
          onClick ={(e) => {history.push({ pathname: '/flow/phoneverificationcode' }) }}
          className={`${themeCss['FilledBlue']} ${themeCss['button']}`}>Fake Action - Verification not successful</button>
      </div>
    </div>
  )
}