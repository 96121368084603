import { useEffect, useState } from 'react'
import { useQuery } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { urlQueryParser } from '../../util/urlQueryParser'

import { adminGetAuctionByCode } from '../auctionsadminutil'

import css from './sass/endedauction.module.scss'
import themeCss from '../../components/sass/theme.module.scss'
import EndedAuctionAttendeeItemRow from './EndedAuctionAttendeeItemRow'


export default function EndedAuctionAttendeePage(props) {
  const [itemsPaidCount, setItemsPaidCount] = useState(0)
  const [itemsUnpaidCount, setItemsUnpaidCount] = useState(0)

  //array of truth for all attendees in auction
  const [attendeeItems, setAttendeeItems] = useState([])
  

  // const [adminAuctionDetail, setAdminAuctionDetail] = useState()
  const { auctionId: auctionCode, attendeeId: userId } = urlQueryParser(props.location.search)

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["adminAuctionDetail", auctionCode],
    queryFn: async () => {
      return await adminGetAuctionByCode(auctionCode)
    },
    staleTime: 0
  })

  //use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])

  // use effect to take adminAuctionDetail and reformat the data for attendee display, assigning it to attendeeArray for mapping in JSX 
  // maybe could reformat into adminAuctionDetail if we don't need all of the details the query returns
  useEffect(() => {

    console.log(auctionDetail)
    if (auctionDetail) {
      const adminItems = auctionDetail?.Items
      
      const userItems = adminItems.filter((item) => item.CurrentHighBidder === userId)

      const itemsPaid = userItems.reduce((acc, item) => {
        if (item.Paid) return acc + 1
        else return acc
      }, 0)

      setItemsPaidCount(itemsPaid)
      setItemsUnpaidCount(userItems.length - itemsPaid)
      setAttendeeItems(userItems)

    }

  }, [auctionDetail, userId])

  if (!auctionDetail || !attendeeItems) return (<></>)

  return (
    <div className={css['ended-auction-container']}>
      

      <h2 id="landingPageTitle">
        {auctionDetail.Title} is Complete / {attendeeItems[0]?.CurrentHighBidderInfo?.FirstName} {attendeeItems[0]?.CurrentHighBidderInfo?.LastName}
      </h2>
        
      <div className={css['ended-auction-summary']}>
        <div>
          <p>{itemsUnpaidCount} ITEMS WITH BALANCE DUE</p>
          <p>{itemsPaidCount} ITEMS WITH FULLY PAID BALANCE</p>
        </div>
      </div>

      <div className={css['ended-auction-charge-selected']}>
        <p>
          {} ITEMS SELECTED
        </p>
        <button className={`${themeCss['button']} ${themeCss['FilledBlue']}`}>
          Send Selected Charges To Treasury
        </button>
        <button className={`${themeCss['button']} ${themeCss['FilledYellow']}`}>
          Allow Alt. Payment
        </button>
      </div>


      <div className={css['ended-auction-table']}>
        <table>
          <thead>
            <tr className={css['ended-auction-table-header']}>
              <th>Item Name</th>
              <th>Item Amount Due</th>
              <th>Item Amount Paid</th>
              <th>Alt. Payment Allowed?</th>
              <th>Sent to Treasury for Payment?</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {attendeeItems && attendeeItems.map((item) => {
            return <EndedAuctionAttendeeItemRow key={item.ItemId} item={item} />
            })}
          </tbody>
        </table>
      </div>
      
    </div>
  )
}