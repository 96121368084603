import React from 'react'

import css from './sass/itempage.module.scss'

export default function ItemInfoBox( {item} ) {

  return (
    <div className={css['item-info-outer']}>
      <div className={css['item-info-inner']}>
        <p>Donated By:</p>
        <p>{item.Donor}</p>
      </div>
    </div>
  )
}