
//subscribes to all current bids
export const onUpdateCurrentBidSubscription = `
  subscription onUpdateCurrentBid($auctionId: String!) {
    onUpdateCurrentBid(AuctionId: $auctionId)  {
      ItemId
      CurrentBid
      BidCount
      CurrentHighBidder
    }
  }`


