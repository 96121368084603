import css from './sass/itembid.module.scss'

import { formatMoney } from 'services/util'

export default function ItemBidInfo({ item, isHighBidder, auctionState }) {

  const biddingClass = (location) => {
    if ( isHighBidder && location === 'text') return css['high-bid-text']
    if (location === 'notification') {
      if (isHighBidder) return css['high-bid-notification']
      else return css['hidden']
    }
    return ''
  }

  const currentBidText = () => {
    if (auctionState === 'ended') return 'Winning Bid'
    if (item.CurrentBid) return 'Current Bid'
    return 'Starting Bid'
  }

  return (
    <div className={css['high-bid-container']}>
      <div className={css['high-bid-header']}>
        <div>
          <p className={`${css['bid-text']} ${biddingClass('text')}`}>
            {currentBidText()}
          </p>
          <p className={`${css['bid-text-large']} ${biddingClass('text')}`}>${formatMoney(item.CurrentBid ? item.CurrentBid : item.StartingBid)}</p>
        </div>
        <p className={biddingClass('notification')}>
          {auctionState === 'ended' ?
          'You have won this item!'
          : 'You are currently the highest bidder'}
        </p>

      </div>
      { auctionState === 'started' &&
      <div className={css['bid-info-box']}>
        <p>{item.BidCount} {item.BidCount === 1 ? 'bid' : 'bids'}</p>
        <p>Min. Raise: ${formatMoney(item.MinRaise)}</p>
        <p>Fair Market Value: ${formatMoney(item.Value)}</p>
      </div>}
    </div>
  )
}