import { useEffect, useState} from 'react'
import requireContext from 'require-context.macro'

import { showLoading } from 'services/util'
import { history } from 'services/history.js'

import { urlQueryParser } from '../../util/urlQueryParser'
import { getPublicAuctionDetail } from '../../api'

import ItemCard from 'sites/auctions/items/ItemCard'

import css from '../sass/flow.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

//temporary fix for images
const images = requireContext('../../auctions/images', true)
const imageList = images.keys().map(image => images(image))

export default function AuctionFromEmail(props) {
  const [auctionDetail, setAuctionDetail] = useState()
  const [userExists, setUserExists] = useState(false)

  const {
    auctionId: auctionCode,
    publicId,
    email,
  } = urlQueryParser(props.location.search)

  localStorage.setItem('savedUsername', email)
  localStorage.setItem('savedAuctionCode', auctionCode)

  console.log(auctionDetail)

  useEffect(() => {
    if (!auctionCode)
      return

    showLoading(true)

    const getDetails = async () => {
      const {success, error} = await getPublicAuctionDetail(auctionCode, publicId, email)
      console.log({success, error})
      showLoading(false)

      if (error || !success || !success.auction) {
        console.log('Error!', error || "No auction found")
      }
      else if (success.auction) {
        setAuctionDetail(success.auction)
        setUserExists(success.userExists)
      }
    }

    getDetails()

  }, [auctionCode, publicId, email])

  if (!auctionDetail) return

    //temporary fix for images
    const banner = auctionDetail.BannerImageName
    const bannerImage = imageList.find(imageLink => imageLink.includes(banner.substring(0, banner.indexOf('.'))))

    return (
      <div className={css['auction-email-container']}>
        <h1 className={css['auction-email-container-header']}>{auctionDetail.Title}</h1>
        <div className={themeCss['blue-background']}>
          <img className={css['auction-email-banner']} src={`${bannerImage}`} alt={'Auction Banner'} />
          <p className={css['auction-email-description']}>{auctionDetail.Description}</p>
          <div className={css['auction-email-buttons']}>
          {/* <button 
              onClick ={(e) => {history.push({
                pathname: '/registration',
              })}}
              className={`${themeCss['FilledYellow']} ${themeCss['button']}`}>Register for Auction</button>
            <button 
              onClick ={(e) => {history.push({
                pathname: userExists ? '/signin' : '/registration',
              })}}
              className={`${css['FilledBlueWhiteBorder']} ${themeCss['button']}`}>Sign In for Auction</button> */}
            <button 
              onClick ={(e) => {history.push({
                pathname: userExists ? '/signin' : '/registration',
              })}}
              className={`${themeCss['FilledYellow']} ${themeCss['button']}`}>Sign In for Auction</button>
          </div>
          {/* {auctionDetail.AuctionItems && auctionDetail.AuctionItems.map((item) => {
          return <ItemCard key={item.ItemCode} item={item}
          // submitBid={submitBid} currentUserId={currentUserId} isCheckedIn={isCheckedIn} failedBid={failedBid}
          auction={auctionDetail} auctionState={'before'} 
          />
        })} */}
        </div>
      </div>
    )
}