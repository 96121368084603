import requireContext from 'require-context.macro'

import { showLoading } from 'services/util'

import css from './sass/adminitem.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import ItemDeleteButton from './ItemDeleteButton'

//temporary fix for images
// const images = requireContext('./images', true)
// const imageList = images.keys().map(image => images(image))

//previewitems
const itemImages = requireContext('../items/images', true)
const itemImageList = itemImages.keys().map(image => itemImages(image))

export default function ItemDisplayRow( {item, setInvalidateItemQuery} ) {

  const getImageLink = (image) => {
    return itemImageList.find(imageLink => imageLink.includes(image.substr(0, image.indexOf('.'))))
  }

  return (
    <div className={css['admin-item-row']}>
      <div className={`${css['admin-item-row-details']} ${css['img']}`}>
        <img src={getImageLink(item.ImageFilename)} alt={item.Title}/>
      </div>

      <div className={`${css['admin-item-row-details']} ${css['editbutton']}`}>
        <button className={`${themeCss['button']} ${themeCss['FilledBlue']}`} onClick={()=> console.log('edit')}>
          Edit Item
        </button>
      </div>
      <div className={`${css['admin-item-row-details']} ${css['deletebutton']}`}>
        <ItemDeleteButton item={item} setInvalidateItemQuery={setInvalidateItemQuery} />
      </div>

      <div className={`${css['admin-item-row-details']} ${css['title']}`}>
        <label>Title</label>
        <p>{item.Title}</p>
      </div>
      <div className={`${css['admin-item-row-details']} ${css['desc']}`}>
        <label>Description</label>
        <p>{item.Description}</p>
      </div>
      <div className={`${css['admin-item-row-details']} ${css['code']}`}>
        <label>Item Code</label>
        <p>{item.ItemCode}</p>
      </div>
      <div className={`${css['admin-item-row-details']} ${css['donor']}`}>
        <label>Donor</label>
        <p>{item.Donor}</p>
      </div>
      <div className={`${css['admin-item-row-details']} ${css['value']}`}>
        <label>Fair Market Value</label>
        <p>{item.Value}</p>
      </div>
      <div className={`${css['admin-item-row-details']} ${css['starting']}`}>
        <label>Starting Bid</label>
        <p>{item.StartingBid}</p>
      </div>
      <div className={`${css['admin-item-row-details']} ${css['minraise']}`}>
        <label>Minimum Raise</label>
        <p>{item.MinRaise}</p>
      </div>
    </div>
  )
}