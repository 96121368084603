import { useEffect, useState } from 'react'
import { useQuery } from "@tanstack/react-query"

import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { formatMoneyCents } from 'services/util'

import { getAuctionByCode } from '../api'
import { urlQueryParser } from '../util/urlQueryParser'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/checkout.module.scss'

import { useSelector } from "react-redux"
import { getAuctionUser } from '../redux/selectors'
import CheckoutHeader from './CheckoutHeader'


export default function CheckoutConfirm(props) {
  //destructuring auction code from url
  //called id in url query for that convention, but using auction code in aws to declutter url from being massive from aws UUID for auction ID to link tables
  const { auctionId: auctionCode } = urlQueryParser(props.location.search)

  // const [auction, setAuction] = useState()
  const [myItems, setMyItems] = useState()
  const [myTotal, setMyTotal] = useState()

  // probably get from auction details in the future?
  const feePercentage = 2

  const auctionUser = useSelector(getAuctionUser);

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["auctionDetail", auctionCode],
    queryFn: async () => {
      console.log('hello')
      return await getAuctionByCode(auctionCode)
    },
    staleTime: 0
  })
  // use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])


  useEffect(() => {
    if (auctionDetail) {
      const pulledItems = auctionDetail.Items.filter((item) => item.CurrentHighBidder === auctionUser.UserId)
      setMyItems(pulledItems)

    }
  }, [auctionDetail, auctionUser.UserId])
  
  useEffect(() => {
    if (myItems) {
      const subtotal = myItems?.reduce(((acc, item) => acc += item.CurrentBid), 0)
      const fees = (subtotal * feePercentage)/100
      const total = (subtotal + fees)

      setMyTotal(formatMoneyCents(total))
    }

  }, [myItems])

  const ccToken = auctionUser?.CreditCardToken
  // const ccToken = undefined  

  if (!auctionDetail || !myItems) return (<></>)
  
  return (
    <div className={css['checkout-container']}>

      <CheckoutHeader auctionCode={auctionDetail.AuctionCode} />

      <p className={css['confirm-header']}>Confirm Payment</p>
      <p className={css['confirm-text']}>Please confirm the payment of the following amount:</p>
      <p className={css['confirm-amount']}>${myTotal}</p>

      <div className={css['checkout-linebreak']}></div>

      <div className={css[`checkout-payment${ccToken ? '' : '-hidden'}`]}>
        <h5>Payment Method</h5>
        <h6>VISA •••{auctionUser?.CreditCardToken?.substring(0,4)}</h6>
      </div>

      {ccToken && <div className={css['checkout-linebreak']}></div>}

      <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${css['checkout-paybutton']}`}
          onClick ={(e) => {history.push({
            pathname: '/checkoutsuccess',
            search: `?auctionId=${auctionCode}`,
          })}}
          >
        {`Confirm $${myTotal} Payment`}
      </button>

      <button className={`${themeCss['button']} ${themeCss['StrokeBlue']} ${css['checkout-paybutton']}`}
          onClick ={(e) => {history.push({
            pathname: '/auctionhome',
            search: `?auctionId=${auctionCode}`
          })}}
          >
        Cancel
      </button>

    </div>
  )
}