import { useEffect, useState } from 'react'
import { useQuery } from "@tanstack/react-query"

import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { formatMoneyCents } from 'services/util'

import { getAuctionByCode } from '../api'
import { urlQueryParser } from '../util/urlQueryParser'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/checkout.module.scss'

import { useSelector } from "react-redux"
import { getAuctionUser } from '../redux/selectors'
import CheckoutItem from './CheckoutItem'
import CheckoutHeader from './CheckoutHeader'


export default function CheckoutAuction(props) {
  //destructuring auction code from url
  //called id in url query for that convention, but using auction code in aws to declutter url from being massive from aws UUID for auction ID to link tables
  const { auctionId: auctionCode } = urlQueryParser(props.location.search)

  const [myItems, setMyItems] = useState()
  const [mySubTotal, setMySubTotal] = useState()
  const [myFees, setMyFees] = useState()
  const [myTotal, setMyTotal] = useState()

  // probably get from auction details in the future?
  const feePercentage = 2

  const auctionUser = useSelector(getAuctionUser);

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["auctionDetail", auctionCode],
    queryFn: async () => {
      console.log('hello')
      return await getAuctionByCode(auctionCode)
    },
    staleTime: 0
  })
  // use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])


  useEffect(() => {
    if (auctionDetail) {
      // setAuction(auctionDetail)
      const pulledItems = auctionDetail.Items.filter((item) => item.CurrentHighBidder === auctionUser.UserId)
      setMyItems(pulledItems)

    }
  }, [auctionDetail, auctionUser.UserId])
  
  useEffect(() => {
    if (myItems) {
      const subtotal = myItems?.reduce(((acc, item) => acc += item.CurrentBid), 0)
      const fees = (subtotal * feePercentage)/100
      const total = (subtotal + fees)

      setMySubTotal(formatMoneyCents(subtotal))
      setMyFees(formatMoneyCents(fees))
      setMyTotal(formatMoneyCents(total))
    }

  }, [myItems])
  // console.log(auctionDetail)
  // console.log(auctionUser)
  // console.log(myItems)

  const ccToken = auctionUser?.CreditCardToken
  // const ccToken = undefined

  const redirectPath = () => {
    if (ccToken) {
      return {
        pathname: '/checkoutconfirm',
        search: `?auctionId=${auctionDetail.AuctionCode}`,
        myTotal, auctionCode
      }
    } else {
      return {
        pathname: '/paymentupdate/checkout',
        search: `?auctionId=${auctionDetail.AuctionCode}`,
      }
    }
  }
  

  if (!auctionDetail || !myItems) return (<></>)
  
  return (
    <div className={css['checkout-container']}>

      <CheckoutHeader auctionCode={auctionDetail.AuctionCode} />
        
      <div className={css['checkout-textline-medium']}>
        <h5>Items Won</h5>
        <p>{`${myItems.length} item${myItems.length === 1 ? '' : 's'}`}</p>
      </div>

      {myItems && myItems.map((item) => {
        return(
          <CheckoutItem item={item} />
        )
      })}

      <div className={css['checkout-linebreak']}></div>

      <div className={css['checkout-textline-medium']}>
        <h5>Subtotal</h5>
        <p>{`$${mySubTotal}`}</p>
      </div>
      <div className={css['checkout-textline-small']}>
        <h5>Fees</h5>
        <p>{`$${myFees}`}</p>
      </div>

      <div className={css['checkout-linebreak']}></div>

      <div className={css['checkout-textline-medium']}>
        <h5>Total</h5>
        <p className={css['checkout-total']}>{`$${myTotal}`}</p>
      </div>

      <div className={css['checkout-linebreak']}></div>

      <div className={css[`checkout-payment${ccToken ? '' : '-hidden'}`]}>
        <h5>Payment Method</h5>
        <h6>VISA •••{auctionUser?.CreditCardToken?.substring(0,4)}</h6>
        <button className={css['checkout-payment-different']}
          onClick ={(e) => {history.push({
            pathname: '/paymentupdate/checkout',
            search: `?auctionId=${auctionDetail.AuctionCode}`,
            // auctionDetail
          })}}
          >Use Different Payment Method</button>
      </div>

      {ccToken && <div className={css['checkout-linebreak']}></div>}

      <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${css['checkout-paybutton']}`}
          onClick ={(e) => {history.push(redirectPath())}}
          >
        {`Pay $${myTotal}`}
      </button>

    </div>
  )
}