import { useState, useEffect, useContext } from 'react'
import { useSelector } from "react-redux"
import { Auth } from 'aws-amplify'

import { emailRegex } from "authentication/validators"
import { getLoggedInUser } from "redux/selectors"
import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { ThemeContext } from 'ThemeContext'

import { updateAuctionUser } from '../api'
import css from './sass/signup.module.scss'
import themeCss from '../components/sass/theme.module.scss'

// the old SignUp component with all the fields is in auctions/archive/SignUp.kjs
export default function AuctionsProfileUpdate() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [emailVerified, setEmailVerified] = useState(false)
  const [emailInVerification, setEmailInVerification] = useState()
  const [mobileNumber, setMobileNumber] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const loggedInUser = useSelector(getLoggedInUser)
  const theme = useContext(ThemeContext)

  useEffect(() => {
    if (!loggedInUser?.cognitoUser) // login process hasn't completed yet
      return

    const login = loggedInUser.cognitoUser.signInUserSession.idToken.payload.cognitoLogin
    const attributes = loggedInUser.cognitoUser.attributes

    if (loggedInUser?.crmUser?.profile?.primaryName) {
      setFirstName(loggedInUser.crmUser.profile.primaryName.firstName)
      setLastName(loggedInUser.crmUser.profile.primaryName.keyName)
    }

    // user has logged in using email
    if (login.indexOf('@') > 0) {
      setEmailAddress(login)
      setEmailVerified(true)
    }
    // user has logged in using mobile
    else {
      // add mobile number to user account since it's verified
      if (login.indexOf('+') === 0 && attributes.phone_number_verified === true) {
        setMobileNumber(login)
      }

      // if mobile user has already verified email in Cognito (this is the first app to do so), don't make them verify again
      if (attributes.email && attributes.email_verified === true) { // important to check for true, I've seen the string "false" before
        setEmailAddress(attributes.email)
        setEmailVerified(true)
      }
      else if (loggedInUser?.crmUser?.profile?.primaryEmail) {
        setEmailAddress(loggedInUser?.crmUser?.profile?.primaryEmail)
        setEmailVerified(true)
      }
    }
 }, [loggedInUser])

  const submitUpdateUser = async (e) => {
    e.preventDefault()
    console.log("submitUpdateUser called")

    showLoading(true)
    setErrorMessage('')

    const userFields = {
      firstName,
      lastName,
      emailAddress,
      phoneNumber: mobileNumber,
    }
    try {
      const response = await updateAuctionUser(userFields)
      console.log(response)
      showLoading(false)
      //reroute to add payment page on successful update
      history.push('/flow/paymentadd')
    }
    catch(e) {
      setErrorMessage('There was an error updating your contact information, please try again or contact support at ' + theme.supportEmail + '.')
      showLoading(false)
    }
  }

  const startVerify = async (e) => {
    e.preventDefault()

    console.log("startVerify!!!")

    setErrorMessage('')

    if (!emailRegex.test(emailAddress)) {
      setErrorMessage('Invalid email address.')
      return
    }

    showLoading(true)

    try {
      const user = await Auth.currentAuthenticatedUser()

      // prevent multiple texts
      if (user.attributes.email === emailAddress && user.attributes.email_verified === false) {
        const verifyCurrentUserAttributeResponse = await Auth.verifyCurrentUserAttribute("email")
        console.log({verifyCurrentUserAttributeResponse})
      }
      else {
        // verification is sent automatically when updating email
        const updateUserAttributesResponse = await Auth.updateUserAttributes(user, { email: emailAddress })
        console.log({updateUserAttributesResponse})
      }

      setEmailInVerification(true)
    }
    catch (e) {
      setErrorMessage(e.message || 'Error in validation. Please try again.')
    }
    showLoading(false)
  }

  const submitVerifyCode = async (e) => {
    e.preventDefault()

    showLoading(true)

    try {
      const verifyCurrentUserAttributeSubmitResponse = await Auth.verifyCurrentUserAttributeSubmit("email", verificationCode)
      console.log({verifyCurrentUserAttributeSubmitResponse})
      setEmailVerified(true)
    }
    catch (e) {
      console.log(e)
      setErrorMessage(e.message)
    }

    showLoading(false)
  }

  const changeContactEmail = async (e) => {
    e.preventDefault()

    setEmailInVerification(false)
    setEmailVerified(false)
  }

  return (
    <>
      <form onSubmit={submitUpdateUser}>
        <div className={css['signup-form']}>
          <div className={css['your-information']}>
            <h2>UPDATE YOUR INFORMATION</h2>
            <hr />

            <div className={css['input-item']}>
              <label>First Name</label>
              <input type='text'
                     id="auctionsProfileFirstNameInput"
                     value={firstName}
                     onChange={(e) => setFirstName(e.target.value)}/>
            </div>

            <div className={css['input-item']}>
              <label>Last Name</label>
              <input type='text'
                     id="auctionsProfileLastNameInput"
                     value={lastName}
                     onChange={(e) => setLastName(e.target.value)}/>
            </div>

            <div className={css['input-item']}>
              <label>Email Address</label>
              <input type='text'
                     id="auctionsProfileEmailInput"
                     required
                     disabled={emailVerified}
                     value={emailAddress}
                     onChange={(e) => setEmailAddress(e.target.value)}/>

              {emailAddress && emailVerified &&
                <span>
                  <button type="button"
                          id="changeContactEmailButton"
                          onClick={changeContactEmail}
                          className={`${themeCss['button']} ${themeCss['FilledBlue']}`}
                    >Change Contact Email</button>
                </span>}

              {emailAddress && !emailVerified &&
                <span>

                  {!emailInVerification &&
                    <button type="button"
                            id="verifyEmailButton"
                            onClick={(e) => startVerify(e)}
                            className={`${themeCss['button']} ${themeCss['FilledBlue']}`}
                      >Verify Email Address</button>}

                  {emailInVerification &&
                    <>
                      <button type="submit"
                              id="submitEmailVerificationCodeButton"
                              onClick={submitVerifyCode}
                              className={`${themeCss['button']} ${themeCss['FilledBlue']}`}
                        >Submit Verification Code</button>

                      <input type="text"
                             id="emailVerificationCodeInput"
                             onChange={e => setVerificationCode(e.target.value)}
                             value={verificationCode}/>
                    </>}

                </span>}
            </div>

            <div className={css['signup-form-button-container']}>
              <button type="submit"
                      id="completeSignUpButton"
                      onClick={submitUpdateUser}
                      disabled={!firstName || !lastName || !emailVerified}
                      className={`${themeCss['button']} ${themeCss['FilledBlue']}`}
                >Update Account</button>
            </div>

            <div className={css['signup-form-button-container']}>
              <button
                      onClick ={(e) => {history.push({ pathname: '/user/paymentupdate' })}}
                      className={`${themeCss['button']} ${themeCss['FilledBlue']}`}
                >Add / Update Credit Card</button>
            </div>

            {errorMessage && <div className={css['update-user-error']}>
              {errorMessage}
            </div>}

          </div>
        </div>
      </form>
    </>
  )
}