import { useEffect } from 'react'
import { useSelector } from "react-redux"

import { getLoggedInUser } from "redux/selectors"
import { history } from 'services/history.js'

import themeCss from '../components/sass/theme.module.scss'

export default function AuctionsUserPage() {

  const loggedInUser = useSelector(getLoggedInUser)

  useEffect(() => {
    if (!loggedInUser?.cognitoUser) // login process hasn't completed yet
      return
 }, [loggedInUser])

  return (
    <>
      <h2>Auction User Page</h2>
      <button 
        onClick ={(e) => {history.push({pathname: '/user/profileupdate'})}}
        className={`${themeCss['FilledBlue']} ${themeCss['button']}`}>
          Update My Information</button>
      <h3></h3>
    </>
  )
}