import { useEffect, useState} from 'react'
import { useSelector } from "react-redux"
import { useQuery } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { getAuctionByCode } from '../api'
import { getAuctionUser } from '../redux/selectors'
import { urlQueryParser } from '../util/urlQueryParser'
import { isAttending } from '../util/userUtils'

// import css from './sass/itemlist.module.scss'

import AuctionCountdown from '../auctions/AuctionCountdown'
import MyEmptyItems from './MyEmptyItems'
import MyWinningItems from './MyWinningItems'

export default function MyAuctionItems(props) {

  const [auctionDetail, setAuctionDetail] = useState()
  const [myItems, setMyItems] = useState()
  // const [date, setDate] = useState(new Date())
  
  const { auctionId: auctionCode } = urlQueryParser(props.location.search)
  const auctionUser = useSelector(getAuctionUser)
  const currentUserId = auctionUser.UserId

  // load first time by auction code, so it will work for deep-linking
  const { data, error, isLoading } = useQuery({
    queryKey: ["auctionDetail", auctionCode],
    queryFn: async () => await getAuctionByCode(auctionCode),
    staleTime: 0
  })

  useEffect(() => {
    showLoading(isLoading)

    if (error || (data && data.error)) {
      console.log('Error!', error || data.error)
    } else if (data) {
      setAuctionDetail(data)
    }
  }, [data, error, isLoading])

  // useEffect(() => {
  //   if (!auctionDetail) return

  //   const timer = setInterval(() => setDate(new Date()), 1000)

  //   return function cleanup() {
  //     clearInterval(timer)
  //   }
  // }, [auctionDetail, date])

  // to update list of my items
  useEffect(() => {
    const filteredItems = auctionDetail?.Items.filter(item => item.CurrentHighBidder === currentUserId).map(item => item.ItemId)
    setMyItems(filteredItems)
  }, [auctionDetail, currentUserId])

  if (!auctionDetail) return (<></>)

  return (
    <div>
      {/* <AuctionCountdown auctionDetail={auctionDetail} userAttending={isAttending(auctionDetail.AuctionId, auctionUser)}/> */}
      {myItems?.length === 0 ? 
        <MyEmptyItems auctionDetail={auctionDetail} /> :
        <MyWinningItems auctionDetail={auctionDetail} myItems={myItems} />
        }
    </div>
  )
}