import { useState, useEffect } from 'react'
import { useQuery, useQueryClient } from "@tanstack/react-query"

import { getAllUpcomingAuctions } from '../api'

import themeCss from '../components/sass/theme.module.scss'

import AuctionCard from './AuctionCard'

export default function AdminPastAuctionsPage() {

  const [pastAuctionList, setPastAuctionList] = useState()
  const [invalidateQuery, setInvalidateQuery] = useState(false)
  const [refresh, setRefresh] = useState(false)

  //to refresh query on changes
  const queryClient = useQueryClient()

  // load auction data by code, so it will work for deep-linking
  const { data: auctions, error, isLoading } = useQuery({
    queryKey: ["allAuctionList"],
    queryFn: async () => await getAllUpcomingAuctions(),
    staleTime: 0
  })

  useEffect(() => {
    if (invalidateQuery) {
      queryClient.invalidateQueries(["allAuctionList"])
      setInvalidateQuery(false)
    }

  }, [invalidateQuery])

  useEffect(() => {
    if (!auctions)
    return

    const now = new Date()
    const upcomingAuctions = auctions.sort((a, b) => (a.StartTime < b.StartTime) ? -1 : (a.StartTime > b.StartTime) ? 1 : 0).map((auction) => { return {...auction, 'displayData': false}})
    const pastAuctions = upcomingAuctions.filter((auction) => auction.EndTime < now.toISOString())

    setPastAuctionList(pastAuctions)
  }, [auctions])

  useEffect(() => {
    if (refresh) setRefresh(false)
  }, [refresh])

  return (
    <div>
      <h1>Past Auctions</h1>
        
      {pastAuctionList && pastAuctionList.map((auction) => <AuctionCard state={'ended'} auction={auction} key={auction.AuctionId} />)}

    </div>
  )
}