import { useState } from 'react'

import css from './sass/adminpage.module.scss'

export default function LiveItems( {auctionDetail, item} ) {
  const [showInfo, setShowInfo] = useState(false)

  //individual item bids sorted with most recent on top
  const itemBids = auctionDetail.Bids.filter(bid=> bid.ItemId === item.ItemId)
  .sort((a,b) => a.Timestamp < b.Timestamp ? 1 : -1)

  const click = () => {
    let current = showInfo
    setShowInfo(!current)
  }

  return (
    <div>
      <button onClick={()=>click()} >{showInfo ? 'Hide Bid Info' : 'Show Bid Info'}</button>
      
      {showInfo && (
          <table className={css['admin-infobids-table']}>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Bid Amount</th>
                <th>Bid Time</th>
              </tr>
            </thead>
            <tbody>
            {itemBids && itemBids.map((bid) => {
                return (
                  <tr key={bid.Timestamp}>
                    <td>{bid.FullName}</td>
                    <td>{bid.BidAmount}</td>
                    <td>{new Date(bid.Timestamp).toLocaleString()}</td>
                    
                  </tr>)
              })}
            </tbody>
          </table>
        )
      }
    </div>    
  )
}