import { useState, useEffect } from 'react'

import IdmReactTable from "common/IdmReactTable"

import DeleteConfirmModal from 'common/ConfirmModal'
import { QueryResultsStyles, AdminButtonWrap } from 'admin/QueryStyles'
import { formatDate, formatPhone } from 'admin/adminutil'
import { FilledYellow } from 'css/Buttons'
import AdminDeleteIcon from 'images/x-red.svg'

import { adminDownloadDocument, deleteDocument, saveDocument } from '../mggadminutil'
import css from '../sass/confirm.modal.module.scss'
import deleteIcon from '../../images/trash-can-icon.png';
import downloadIcon from '../../images/download-icon.png';


function DocumentSearchResults(props) {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showBlockedModal, setShowBlockedModal] = useState(false)
  const [docToEdit, setDocToEdit] = useState(null)
  const [indexToEdit, setIndexToEdit] = useState(null)
  const [blockedCrmIds, setBlockedCrmIds] = useState(null)

  const tableProps = {
    initialState: {
      sortBy: [
       {
        id: 'ReportYear',
        desc: true
       }
      ]
    },
  }

  const displayBlockedCrmIds =  ({ row })  => {
    if (row.original.ReportType === 'EIAR' || row.original.ReportSubType === 'User') {
      return null;
    }
    else if (row.original.BlockedCrmIds && row.original.BlockedCrmIds.length > 0) {
      return (<span title='Edit blocked CRM IDs' className={css.rowIcon} onClick={e => editBlockedCrmIds(row.original)}>{row.original.BlockedCrmIds.join(", ")} <strong>&#x270E;</strong></span>)
    }
    else {
      return (<span title='Add blocked CRM IDs' className={css.rowIcon} onClick={e => editBlockedCrmIds(row.original)}>&#10133;</span>)
    }
  }

  const displayDownloadIcon = ({ row }) => (
    <center className={css.rowIcon}><img width="25" height="25" title='DOWNLOAD' alt='' src={downloadIcon}
            onClick={e => adminDownloadDocument(row.original)} /></center>
  )

  const displayDeleteIcon = ({ row }) => (
    <center className={css.rowIcon}><img width="25" height="25" title='DELETE' alt='' src={deleteIcon}
            onClick={e => confirmDeleteDocument(row.original, row.index)} /></center>
  )

  const mainTableColumns = {
    AllDocuments: [
      { Header: 'Year', desc: false, accessor: 'ReportYear'},
      { Header: 'Type', desc: false, accessor: row => row.ReportSubType ? row.ReportType + '/' + row.ReportSubType : row.ReportType},
      { Header: 'ID or #', id: 'fundNumber', accessor: row => row.ReportNumber || row.CrmId || row.ReportId},
      { Header: 'File Name', desc: true, accessor: 'FileName'},
      { Header: 'Blocked CRM IDs', desc: true, Cell: displayBlockedCrmIds},
      { Header: ' ', desc: true, Cell: displayDownloadIcon },
      { Header: '  ', desc: true, Cell: displayDeleteIcon },
    ],
    EIAR: [
      { Header: 'Year', desc: false, accessor: 'ReportYear'},
      { Header: 'Type', desc: false, accessor: 'ReportType'},
      { Header: 'CRM ID', desc: false, accessor: 'CrmId'},
      { Header: 'File Name', desc: true, accessor: 'FileName'},
      { Header: ' ', desc: true, Cell: displayDownloadIcon },
      { Header: '  ', desc: true, Cell: displayDeleteIcon },
    ],
    CEIR: [
      { Header: 'Year', desc: false, accessor: 'ReportYear'},
      { Header: 'Type', desc: false, accessor: 'ReportType'},
      { Header: 'Fund #', desc: false, accessor: 'ReportNumber'},
      { Header: 'File Name', desc: true, accessor: 'FileName'},
      { Header: 'Blocked CRM IDs', desc: true, Cell: displayBlockedCrmIds},
      { Header: ' ', desc: true, Cell: displayDownloadIcon },
      { Header: '  ', desc: true, Cell: displayDeleteIcon },
    ],
    ACR: [
      { Header: 'Year', desc: false, accessor: 'ReportYear'},
      { Header: 'Type', desc: false, accessor: row => row.ReportType + '/' + row.ReportSubType},
      { Header: 'ID or #', id: 'fundNumber', accessor: row => row.ReportNumber || row.CrmId || row.ReportId},
      { Header: 'File Name', desc: true, accessor: 'FileName'},
      { Header: 'Blocked CRM IDs', desc: true, Cell: displayBlockedCrmIds},
      { Header: ' ', desc: true, Cell: displayDownloadIcon },
      { Header: '  ', desc: true, Cell: displayDeleteIcon },
    ],
  }

  const confirmDeleteDocument = (doc, index) => {
    setDocToEdit(doc)
    setIndexToEdit(index)
    setShowDeleteModal(true)
  }

  const finishDeleteDocument = async (e) => {
    const deleteReponse = await deleteDocument(docToEdit)

    if (!deleteReponse || deleteReponse.error || deleteReponse.errorMessage) {
      let errorMessage = "There was an error deleting the document."
      if (deleteReponse)
        errorMessage = deleteReponse.errorMessage || deleteReponse.error || errorMessage
      alert(errorMessage.msg || errorMessage)
    }
    else {
      delete props.data[indexToEdit]
    }
    setShowDeleteModal(false)
  }

  const editBlockedCrmIds = (doc) => {
    setDocToEdit(doc)
    setBlockedCrmIds((doc.BlockedCrmIds || []).join(", "))
    setShowBlockedModal(true)
  }

  const updateBlockedCrmIds = (e) => {
    setBlockedCrmIds(e.target.value)
  }

  const saveEditedDocument = async (e) => {
    const blockedCrmIdString = blockedCrmIds.replace(/\s/g, '')
    docToEdit.BlockedCrmIds = blockedCrmIdString ? blockedCrmIdString.split(',') : []

    for (const crmId of docToEdit.BlockedCrmIds) {
      if (!/^\d{10}$/.test(crmId)) {
        alert('All CRM IDs must be 10 digits')
        return
      }
    }

    const saveResponse = await saveDocument(docToEdit)

    if (!saveResponse || saveResponse.error || saveResponse.errorMessage)
      alert('There was an error saving this document ' + (saveResponse ? saveResponse.error || saveResponse.errorMessage : ''))

    setShowBlockedModal(false)
  }

  const copyTableToClipboard = () => {
    const textToCopy = [mainTableColumns.map(c => c.Header).join('\t')];
    props.data.forEach(row => {
      textToCopy.push(mainTableColumns.map(c => {
        if (["LastLogin","DateCreated","DateUpdated"].indexOf(c.accessor) > -1)
          return formatDate(row[c.accessor] || '');
        else
          return (c.accessor === 'PrimaryCrmId' ? "'" : '') + formatPhone((row[c.accessor] || ''));
      })
      .join('\t'))
    })
    navigator.clipboard.writeText(textToCopy.join('\n'))
  }

  // if (props.data.length > 2) {
  //   props.data[0].BlockedCrmIds=["0123456789", "0123456789", "0123456789", "0123456789", "0123456789", "0123456789", "0123456789", "0123456789", "0123456789"]
  //   props.data[1].BlockedCrmIds=["0123456789"]
  //   props.data[2].BlockedCrmIds=["0123456789", "0123456789", "0123456789"]
  // }

  return (
    <QueryResultsStyles searchType={'documents'}>
      {props.data.length > 0 &&
        <IdmReactTable
          { ...tableProps }
          data={ props.data }
          columns={ mainTableColumns[props.documentType] }
        />}

       {showDeleteModal &&
        <DeleteConfirmModal
          onConfirm={finishDeleteDocument}
          onCancel={() => setShowDeleteModal(false)}
          cancelText={"CANCEL"}
          confirmText={"OK"}
        >
          <div className={css.modalInner}>
            <div><img src={AdminDeleteIcon} alt='Delete Document icon'/></div>
            <div className={css.modalText}>
              Are you sure you want to delete the file:
              <br/><strong>{docToEdit.FileName}</strong>?
            </div>
          </div>
        </DeleteConfirmModal>
      }

       {showBlockedModal &&
        <DeleteConfirmModal
          onConfirm={saveEditedDocument}
          onCancel={() => setShowBlockedModal(false)}
          cancelText={"CANCEL"}
          confirmText={"SAVE"}
        >
          <div className={css.modalInner}>
            <div className={css.modalText}>
              CRM IDs to block for <b>{docToEdit.FileName}:</b>
              <br/><textarea cols="100" rows="4" name="blockedCrmIds"
                             onChange={updateBlockedCrmIds}
                             value={blockedCrmIds}
                             className="blocked-crm-ids"></textarea>
              <br/><p>(separate CRM IDs with commas, whitespace is ignored)</p>
            </div>
          </div>
        </DeleteConfirmModal>
      }

     <section>
        <div>
          <span>Total Results: </span>{props.data.length}
          {props.data.length > 0 &&
            <AdminButtonWrap>
              <FilledYellow
                width="7.0625rem"
                bgColor={"#FFD100"}
                fontColor={"#005587"}
                borderColor={"#FFD100"}
                name={props.btnName}
                onClick={copyTableToClipboard}
              >
                <span>Copy to Clipboard</span>
              </FilledYellow>
            </AdminButtonWrap>}

        </div>
      </section>
    </QueryResultsStyles>
  )
}

export default DocumentSearchResults
