import { history } from 'services/history.js'

import css from '../sass/flow.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function InviteeOnlyPage() {

  return (
    <div className={css['flow-container-w-margin']}>
      <h3 className={css['centered']}>Couldn't find this email on the guest list</h3>
      <p >It looks like the email associated with your account does not have permissions to access this auction. Please sign-out and then sign up using the email address at which you received the auction invitation.</p>
    
      <button 
        onClick ={(e) => {history.push({pathname: '/flow/emaillink' }) }}
        className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>
          Sign-Out</button>

      <p>Remove this button with user checking</p>

      <button 
        onClick ={(e) => {history.push({pathname: '/flow/paymentrouter' }) }}
        className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>
          Click to Pass user test</button>
    </div>
  )
}