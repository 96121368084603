import { useState, useEffect } from 'react'

import css from './sass/checkin.module.scss'
import themeCss from '../components/sass/theme.module.scss'


export default function AdminCheckIn() {

  const auctionUser = JSON.parse(localStorage.getItem('auctionUser'))
  const myAuctions = auctionUser.MyAuctions

  const [registeredAuctions, setRegisteredAuctions] = useState([]);

  useEffect(() => {
    const formattedAuctions = myAuctions.map((auction) => {
      if (auction.AuctionCode) return auction
      if (auction.Auction) return auction.Auction
    })

    setRegisteredAuctions(formattedAuctions)
  }, [])


  // const [registeredAuctions, setRegisteredAuctions] = useState(auctionUser.MyAuctions)

  const toggleStatus = (code) => {
    const index = registeredAuctions.findIndex((ele) => ele.AuctionCode === code)
    const updatedAuction = registeredAuctions[index]
    
    if (updatedAuction.CheckedIn) {
      updatedAuction.CheckedIn = false
    } else {
      updatedAuction.CheckedIn = true
    }

    const newAuctions = [...registeredAuctions]
    newAuctions[index] = updatedAuction

    setRegisteredAuctions(newAuctions)
  }


  console.log(registeredAuctions)

  return (
    <div>
      <h2>Auctions current user is signed up for:</h2>
      <table>
        <thead>
          <tr>
            <th>Auction Name</th>
            <th>Check in status</th>
            <th>Edit status</th>
          </tr>
        </thead>
      {registeredAuctions && registeredAuctions.map((auction) => {
        return(
          <tr>
            <td>{auction?.Title}</td>
            <td>{auction?.CheckedIn === true ? 'Checked in' : "Not Checked In"}</td>
            <td>
              <button onClick={() => toggleStatus(auction.AuctionCode)}>
                {auction?.CheckedIn === true ? 'Remove Check in' : "Check in"}
              </button>
            </td>
          </tr>
        )
      })}
      </table>
    </div>
  )
}