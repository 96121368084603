export const validCardNumber = (input) => {

  let lengthMessage = 'Credit card number needs to be 16 numbers long'
  let nanMessage = 'Credit card number must contain only numbers'

  if (input === undefined || input.length !== 16) return [lengthMessage]

  for (let i = 0; i < input.length; i++) {
    if (isNaN(input[i])) {
      return [nanMessage]
    }
  }

  return true
}

export const validExp = (input) => {

  let message = 'Expiration date needs to be in MM/YYYY format'

  if (input?.length !== 7) return [message]

  for (let i = 0; i < input.length; i++) {
    if (i === 2) {
      if (input[i] !== '/') {
        return [message]
      }
    } 
    if (i !== 2 && isNaN(parseInt(input[i]))) return [message]

  }
  
  return true
}

export const saveCalInvite = (event) => {
  const newEvent = { ...event, address: event?.event_url ? event?.event_url : `${event?.address?.line_1}, ${event?.address?.line_2}, ${event?.address?.city} ${event?.address?.state}, ${event?.address?.country} ${event?.address?.postal_code} ` }
  // Create the .ics URL

  let url = [
    "BEGIN:VCALENDAR",
    "BEGIN:VTIMEZONE",
    "BEGIN:STANDARD", 
    "END:STANDARD",
    "END:VTIMEZONE",
    "BEGIN:VEVENT",
    "DTEND;TZID=America/Los_Angeles:20240116T100000",
    "DTSTART;TZID=America/Los_Angeles:20240116T090000",
    "LOCATION:Los Angeles, CA",
    "SUMMARY:Test Auction",
    "UID:50FF5D5C-8D15-4BFA-815E-A66DC19FC5B4",
    "END:VEVENT",
    "END:VCALENDAR"
  ].join("\n")

  let blob = new Blob([url], { type: 'text/calendar;charset=utf-8' })

  if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
    // Open/Save link in IE and Edge
    window.navigator.msSaveBlob(blob, `.ics`)
  } else {
    // Open/Save link in Modern Browsers
    // console.log(encodeURI("data:text/calendar;charset=utf8," + url))
    window.open(encodeURI("data:text/calendar;charset=utf8," + url))
  }

}

export const returnCalData = (event) => {
  const newEvent = { ...event, address: event?.event_url ? event?.event_url : `${event?.address?.line_1}, ${event?.address?.line_2}, ${event?.address?.city} ${event?.address?.state}, ${event?.address?.country} ${event?.address?.postal_code} ` }
  // Create the .ics URL

  let url = [
    "BEGIN:VCALENDAR",
    "BEGIN:VTIMEZONE",
    "BEGIN:STANDARD", 
    "END:STANDARD",
    "END:VTIMEZONE",
    "BEGIN:VEVENT",
    "DTEND;TZID=America/Los_Angeles:20240116T100000",
    "DTSTART;TZID=America/Los_Angeles:20240116T090000",
    "LOCATION:Los Angeles, CA",
    "SUMMARY:Test Auction",
    "UID:50FF5D5C-8D15-4BFA-815E-A66DC19FC5B4",
    "END:VEVENT",
    "END:VCALENDAR"
  ].join("\n")

  let blob = new Blob([url], { type: 'text/calendar;charset=utf-8' })

  if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
    // Open/Save link in IE and Edge
    window.navigator.msSaveBlob(blob, `.ics`)
  } else {
    // Open/Save link in Modern Browsers
    // console.log(encodeURI("data:text/calendar;charset=utf8," + url))
    window.open(encodeURI("data:text/calendar;charset=utf8," + url))
    return "data:text/calendar;charset=utf8," + url
  }

}


/* 


full sample .ics file:

created from iCal and imported

BEGIN:VCALENDAR
CALSCALE:GREGORIAN
PRODID:-//Apple Inc.//macOS 14.1.1//EN
VERSION:2.0
X-WR-CALNAME:Calendar
BEGIN:VTIMEZONE
TZID:America/Los_Angeles
BEGIN:DAYLIGHT
DTSTART:20070311T020000
RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=2SU
TZNAME:PDT
TZOFFSETFROM:-0800
TZOFFSETTO:-0700
END:DAYLIGHT
BEGIN:STANDARD
DTSTART:20071104T020000
RRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU
TZNAME:PST
TZOFFSETFROM:-0700
TZOFFSETTO:-0800
END:STANDARD
END:VTIMEZONE
BEGIN:VEVENT
CREATED:20240109T231213Z
DTEND;TZID=America/Los_Angeles:20240116T100000
DTSTAMP:20240109T231244Z
DTSTART;TZID=America/Los_Angeles:20240116T090000
LAST-MODIFIED:20240109T231213Z
LOCATION:location
SEQUENCE:0
SUMMARY:name
TRANSP:OPAQUE
UID:50FF5D5C-8D15-4BFA-815E-A66DC19FC5B4
X-APPLE-CREATOR-IDENTITY:com.apple.calendar
X-APPLE-CREATOR-TEAM-IDENTITY:0000000000
END:VEVENT
END:VCALENDAR



*/