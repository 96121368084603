import { history } from 'services/history.js'
import css from './sass/checkout.module.scss'

import blueX from '../auctions/images/x-primary-blue.svg'

export default function CheckoutHeader( {auctionCode}) {
  
  if (!auctionCode) return (<></>)
  
  return (
    <div>

      <div className={css['checkout-heading']}>
        <h2>Checkout</h2>
        <img src={blueX} className={css['checkout-heading-img']}
            onClick ={(e) => {history.push({
              pathname: '/auctionhome',
              search: `?auctionId=${auctionCode}`
            })}}
          />
      </div>
      <div className={css['checkout-linebreak']}></div>

    </div>
  )
}