import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useQueryClient } from "@tanstack/react-query"

// import whiteEditIcon from './images/whiteEditIcon.svg'
// import whiteDeleteIcon from './images/whiteDeleteIcon.svg'
// import deleteIcon from '../../../images/deleteIcon.svg'

import { history } from 'services/history.js'
import { getUpcomingAuctions } from '../api'

import css from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import CreateAuction from './CreateAuction'
import AuctionCard from './AuctionCard'
// import AuctionEdit from './AuctionEdit'
// import AuctionDeleteButton from './AuctionDeleteButton'


export default function AdminAuctionPage(props) {

  const [showAuctionModal, setShowAuctionModal] = useState(false)
  const [liveAuctionList, setLiveAuctionList] = useState([])
  const [upcomingAuctionList, setUpcomingAuctionList] = useState([])
  const [invalidateQuery, setInvalidateQuery] = useState(false)
  const [refresh, setRefresh] = useState(false)

  //used to pass down codes for create auction to validate for no duplicate codes
  const [auctionCodes, setAuctionCodes] = useState([])

  //to refresh query on changes
  const queryClient = useQueryClient()

  // load auction data by code, so it will work for deep-linking
  const { data: auctions, error, isLoading } = useQuery({
    queryKey: ["upcomingAuctionList"],
    queryFn: async () => await getUpcomingAuctions(),
    staleTime: 0
  })

  const showCreateAuctionModal = () => {
    let newValue = !showAuctionModal
    setShowAuctionModal(newValue)
  }

  useEffect(() => {
    if (invalidateQuery) {
      queryClient.invalidateQueries(["upcomingAuctionList"])
      setInvalidateQuery(false)
    }

  }, [invalidateQuery, queryClient])

  useEffect(() => {
    if (!auctions)
    return

  const allUpcomingAuctions = auctions.sort((a, b) => (a.StartTime < b.StartTime) ? -1 : (a.StartTime > b.StartTime) ? 1 : 0).map((auction) => { return {...auction, 'displayData': false}})

  let upcomingAuctions = []
  let liveAuctions = []

  const now = new Date()
  const nowStr = now.toISOString()
  

  allUpcomingAuctions.forEach((auction) => {
    
    if (auction.StartTime < nowStr) liveAuctions.push(auction)
    else upcomingAuctions.push(auction)
  })


  setLiveAuctionList(liveAuctions)
  setUpcomingAuctionList(upcomingAuctions)
  setAuctionCodes(auctions.map(auction => auction.AuctionCode))
  }, [auctions])


  useEffect(() => {
    if (refresh) setRefresh(false)
  }, [refresh])

  return (
    <div>
      {liveAuctionList && <div className={css['auction-blocks']}>
        <h2 className={css['auction-headers']}>Live Auctions</h2>
        {liveAuctionList.map((auction) => <AuctionCard state={'live'} auction={auction} key={auction.AuctionId} />)}
      </div>}
      {upcomingAuctionList && <div className={css['auction-blocks']}>
        <h2 className={css['auction-headers']}>Upcoming Auctions</h2>
        {upcomingAuctionList.map((auction) => <AuctionCard state={'upcoming'} auction={auction} key={auction.AuctionId} />)}
      </div>}

      <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`}
              onClick={()=>showCreateAuctionModal()}>
        Create New Auction
      </button>

      {/* Demo: to link between admin pages, use props.history, to link to pages outside admin, use imported history*/}
      <button onClick={(e) => {history.push({pathname: '/auctions'})}}>Demo Link to non-admin Page</button><br/>
      <button onClick={(e) => {props.history.push({pathname: '/admin/createauction'})}}>Demo New Auction Image Upload</button><br/>

      {/* Link will always use props.history */}
      <Link to={{ pathname: '/admin/createauction' }}>Demo Auction Image Upload</Link>


      {showAuctionModal && 
        <>
          <div className={themeCss['modal-background']} onClick={()=>showCreateAuctionModal()}></div>
          <div className={css['auction-modal']}>
            <button className={`${themeCss['button']} ${themeCss['FilledYellow']} ${themeCss['centered-button']}`} onClick={()=>showCreateAuctionModal()} >Close Modal</button>
            <CreateAuction setInvalidateQuery={setInvalidateQuery} auctionCodes={auctionCodes} />
          </div>
        </>
      }
    </div>
  )
}