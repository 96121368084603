import { Link } from 'react-router-dom'
import requireContext from 'require-context.macro'

import css from './sass/myitems.module.scss'

//temporary fix for images
const images = requireContext('./images', true)
const imageList = images.keys().map(image => images(image))

export default function MyWinningItems({auctionDetail, myItems}) {

  const myWinningItems = auctionDetail.Items.filter((item) => myItems?.includes(item.ItemId))
  
  const getImageLink = (item) => {
    let itemImage = null
    if (item) {
        const image = item.ImageFilename
        itemImage = imageList.find(imageLink => imageLink.includes(image.substr(0, image.indexOf('.'))))
    }
    return itemImage
  }

  if (!myWinningItems) return (<></>)

  return (
    <div>
      <h3>Items you're winning from <Link to={{ pathname: '/auctionhome', search: `?auctionId=${auctionDetail.AuctionCode}` }}> {auctionDetail.Title} </Link></h3>
      <table className={css['my-winning-table']}>
        <thead>
          <tr>
            <th>img</th>
            <th>Name</th>
            <th>Bid Count</th>
            <th>Current Bid</th>
          </tr>
        </thead>
        <tbody>
        {myWinningItems
          .sort((a,b) => a.Title.toLowerCase() < b.Title.toLowerCase() ? -1 : 1)
          .map((item, idx) => {
            return (
            <tr key={item.ItemId}>
              <td className={css['my-winning-image']}> 
                <div>
                  <img src={getImageLink(item)} alt={item.Title} /> 
                </div>
              </td>
              <td> 
              <Link 
                className={css['item-link']}
                to={{
                  pathname: '/auction/item',
                  search: `?auctionId=${auctionDetail.AuctionCode}&itemId=${item.ItemCode}`,
                }}>
                {item.Title}
              </Link>
              </td>
              <td>{item.BidCount}</td>
              <td>${item.CurrentBid}</td>    
            </tr>)
        })}
        </tbody>
      </table>
    </div>
  )
}