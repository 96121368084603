import { useState } from 'react'
import { Link } from 'react-router-dom'

import requireContext from 'require-context.macro'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/itemcard.module.scss'
import ItemBidInfo from './ItemBidInfo'

import BidSelector from './BidSelector'

//temporary fix for images
const images = requireContext('./images', true)
const imageList = images.keys().map(image => images(image))

export default function ItemCard({item, auction, auctionState, currentUserId, isCheckedIn, setBidItem, setShowBidModal, updateBidModalAmount }) {

  const [itemBidAmount, setItemBidAmount] = useState()

  //temporary fix for images
  let itemImage = null
  if (item) {
      const image = item.ImageFilename
      itemImage = imageList.find(imageLink => imageLink.includes(image.substr(0, image.indexOf('.'))))
  }

  if (isCheckedIn === undefined) isCheckedIn = false

  const isHighBidder = item.CurrentHighBidder === currentUserId

  if (auctionState === 'ended' && !isHighBidder) return <></> 

  return (
    <div className={`${css['card-container']} ${themeCss['shadowed']}`}>
      <Link className={css['card-image']} to={{ pathname: '/auction/item', search: `?auctionId=${auction.AuctionCode}&itemId=${item.ItemCode}`}}>
        <img src={itemImage} alt={`${item.Title}`} />
      </Link>

      <Link className={css['card-title']} to={{ pathname: '/auction/item', search: `?auctionId=${auction.AuctionCode}&itemId=${item.ItemCode}`}}>
        <p className={css['card-title']}>
          {item.Title}
        </p>
      </Link>

      {auctionState !== 'before' && <ItemBidInfo auctionState={auctionState} isHighBidder={isHighBidder} item={item} /> }

      {(auctionState === 'started' && isCheckedIn === true) && (
        <>
          <div className={`${css['bid-selector-container']}`} >
            <BidSelector item={item} itemBidAmount={itemBidAmount} setItemBidAmount={setItemBidAmount}
            updateBidModalAmount={updateBidModalAmount}
            />
          </div>
          <button className={`${themeCss['button']} ${themeCss['FilledYellow']} ${css['place-bid-button']}`}
            onClick={() => {
                setBidItem(item)
                setShowBidModal(true)
                updateBidModalAmount(itemBidAmount)
              }}>
              PLACE BID
          </button>
        </>
        )}
      {(auctionState === 'started' && isCheckedIn === false) && (
        <button className={`${themeCss['button']} ${themeCss['FilledYellow']} ${css['place-bid-button']}`}
          disabled={true}>
        Check In to Place Bid</button>)}
      <p className={css['card-description']}>{item.Description}</p>
    </div>
  )
}