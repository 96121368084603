import { NavLink } from "react-router-dom"

import AdminLinkIcon from 'images/admin-link-icon.svg'
import AdminAuditIcon from 'images/admin-audit-icon.svg'
import AdminUsersIcon from 'images/admin-users-icon.svg'
import AdminBrowseIcon from 'images/admin-browse-icon.svg'
import AdminDeleteIcon from 'images/x-red.svg'
// import AdminItemIcon from './images/price-tag-svgrepo-com.svg'
import { AdminList, AdminListItem } from 'admin/AdminViewListStyles'
import CheckIcon from '../../mygiving/images/icon-check-green.svg'

import AdminAuctionsIcon from './images/admin-live-auction-icon.svg'
import AdminPastAuctionsIcon from './images/admin-auctions-icon.svg'

const AdminViewList = (props) => {
  return (
    <AdminList >
      <ul>
        <hr />
        <div className={"divider"}>
          Shared Admin Tools
        </div>
        <hr />

        <AdminListItem>
          <NavLink className="bold" id="AdminLinkUserToCrmLink" activeClassName="selectedTab" to="/admin/linkusertocrm">
            <img src={AdminLinkIcon} alt='Link Donor icon'/>
            <div><span>LINK USER TO CRM</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold" id="AdminAuditLink"  activeClassName="selectedTab" to="/admin/history">
            <img className="admin-users-svg" src={AdminAuditIcon} alt='Admin Change History icon'/>
            <div><span>CHANGE HISTORY</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold" id="AdminUsersLink"  activeClassName="selectedTab" to="/admin/users">
            <img src={AdminUsersIcon} alt='Admin Users icon'/>
            <div><span>USERS</span></div>
          </NavLink>
        </AdminListItem>

        <div className={"divider"}>
          Auctions Admin
        </div>
        <hr />

        <AdminListItem>
          {/* TODO: hardcoding the auction code for now - in the future this section might only appear if there is a live auction */}
          <NavLink className="bold"  id="AdminAuctionsLink"  activeClassName="selectedTab" to="/admin/auctions">
            <img  src={AdminAuctionsIcon} alt='View or modify Auctions'/>
            <div><span>VIEW / MODIFY AUCTIONS</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold" id="AdminPastAuctionsLink" activeClassName="selectedTab" to="/admin/pastauctions">
            <img  src={AdminPastAuctionsIcon} alt='View Past Auctions'/>
            <div><span>View Past Auctions</span></div>
          </NavLink>
        </AdminListItem>
        {/* <AdminListItem>
          <NavLink className="bold" id="AdminAuctionAdminLink" activeClassName="selectedTab" to="/admin/items">
            <img  src={AdminItemIcon} alt='Create Or Modify Items icon'/>
            <div><span>CREATE/MODIFY ITEMS</span></div>
          </NavLink>
        </AdminListItem> */}
        <AdminListItem>
          <NavLink className="bold"  id="AdminBrowseAsUserLink"  activeClassName="selectedTab" to="/admin/browseasuser">
            <img  src={AdminBrowseIcon} alt='Browse As User icon'/>
            <div><span>BROWSE AS USER</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold"  id="AdminDeleteUserLink"  activeClassName="selectedTab" to="/admin/deleteauctionuser">
            <img src={AdminDeleteIcon} alt='Delete User icon' id="delete-icon"/>
            <div><span>DELETE USER</span></div>
          </NavLink>
        </AdminListItem>
        {/* <AdminListItem>
          <NavLink className="bold"  id="AdminCheckIn"  activeClassName="selectedTab" to="/admin/checkin">
            <img src={CheckIcon} alt='Check icon' id="check-icon"/>
            <div><span>CHECK IN</span></div>
          </NavLink>
        </AdminListItem> */}

      </ul>
    </AdminList>
  )
}

export default AdminViewList
