import { useEffect, useState } from 'react'
import { showLoading } from 'services/util';

import { graphql, subscribe, getLiveAuctionData } from '../api'
import { updateCurrentBidMutation } from '../graphql/Mutations'
import { onUpdateCurrentBidSubscription } from '../graphql/Subscriptions'

const auctionId = "2d7c458a-6216-11ee-8c99-0242ac120002"

export default function LiveBids() {
  const [item, setItem] = useState()
  const [bidResponse, setBidResponse] = useState()
  const [bidAmount, setBidAmount] = useState()
  const [allItems, setAllItems] = useState()

  // this is executed any time a bid is successfully updated
  // use the result to update the current bid for that item on the client
  useEffect(() => {
    console.log({item})

    if (!item) return

    const subCreateFiltered = subscribe({query: onUpdateCurrentBidSubscription, variables: {auctionId: item.AuctionId}}, {
      next: (result) => {
        console.log('subscription result', result)
        setItem(result.value.data.onUpdateCurrentBid)
      },
      error: (error) => console.log(error)
    })

    return () => {
      console.log('unsubscribing...')
      subCreateFiltered.unsubscribe()
    }
  }, [item])

  // Creates Auction User with new entries in auctions-cognito-login-mapping and auctions-users
  const updateLiveBid = async (bidFields) => {
    console.log('updateLiveBid!')
    try {
      const response = await graphql({query: updateCurrentBidMutation, variables: bidFields })
      if (!response?.data?.updateCurrentBid?.BidCount) {
        console.log("Bid failed: ", response)
        return {error: {response}}
      }
      else {
        return response.data.updateCurrentBid
      }
    }
    catch (e) {
      console.log("error in createAuctionUser:", e)
      if (e.toString().startsWith("Error: No current user")) {
         // In practice we will redirect the user to the login page here
         return {error: {message: "You need to be logged in to use this service."}}
      }
      else if (e.errors[0]?.message?.startsWith("The conditional request failed")) {
        // reloading all data to see what actual current price is
        // in practice we will know if the user was outbid or lost to an equal bid getting in ahead of them, but for now this is ok
        return {error: {message: "Oops you've been outbid or another bid snuck in just ahead of you. Would you like to bid again?"}}
      }
      else {
        return {error: {e}}
      }
    }
    finally {
      // always refersh list on any attempted bid
      setAllItems(await getLiveAuctionData(auctionId))
    }
  }

  const submitBid = async () => {
    showLoading(true)

    const bidFields = {
      itemId: "f06a9b44-621d-11ee-8c99-0242ac120002",
      bidAmount,
      // for now we'll use this to notify someone that they've been outbid, since app sync won't let us return the previous item
      // and anything else stands the risk of getting out of sync
      // eventually the solution will be to call a lambda from appsync which goes to dynamo manually and returns UPDATED_ALL, so we can get the old values in one atomic update call
    }
    try {
      setBidResponse(await updateLiveBid(bidFields))
    }
    catch(e) {
      setBidResponse(e)
    }
    showLoading(false)
  }

  const submitGetAllItems = async () => {
    showLoading(true)

    try {
      const response = await getLiveAuctionData(auctionId)
      setAllItems(response)
    }
    catch(e) {
      console.log("Error looking up all bids: ", e)
      setAllItems(e)
    }
    showLoading(false)
  }

  return (
    <div style={{padding: "20px"}}>
      Bid Amount: <input type="text" onChange={e => setBidAmount(e.target.value)} value={bidAmount}/>
      <p />
      <button onClick={submitBid}>Test Submit Bid</button>
      <p />
      Submit bid response: {bidResponse && <div style={{border: "black 1px solid"}}><pre>{JSON.stringify(bidResponse,0,2)}</pre></div>}
      <p />
      Subscription Result: <div style={{border: "black 1px solid"}}><pre>{JSON.stringify(item,0,2)}</pre></div>
      <p />
      <button onClick={submitGetAllItems}>Retrieve all current bid data for all items</button>
      <p />
      All items with current bids: {allItems && <div style={{border: "black 1px solid"}}><pre>{JSON.stringify(allItems,0,2)}</pre></div>}
    </div>
  )
}