import { Link } from 'react-router-dom'

import css from './sass/endedauction.module.scss'

export default function LiveAuctionRegistrationRow( {registration, auctionCode} ) {
  // console.log(registration)

  const formatName = (registration) => {
    return `${registration.User.FirstName} ${registration.User.LastName}`
  }


  return (
    <tr className={css['ended-auction-table-data-row']}>
      <td>{formatName(registration)}</td>
      <td>{registration.IsCheckedIn ? 'Yes' : "No"}</td>
      <td>{registration.User.ContactEmail}</td>
      <td>{registration.User.CrmId}</td>
      <td>{registration.User.ContactPhone}</td>
    </tr>
  )
}