import QrCode from '../images/mock-qr.jpg'
import CalendarIcon from '../../auctions/images/calendar-plus-icon.svg'

import { saveCalInvite } from '../../components/utils'

import css from '../sass/registerqr.module.scss'
import auctionCss from '../../auctions/sass/auctionscards.module.scss'
import themeCss from '../../components/sass/theme.module.scss'
import QrConfirmRow from './QrConfirmRow'
import GoogleButton from './GoogleButton'

export default function FakeQrCard( {auction} ) {

  const email = localStorage.getItem('savedUsername')

  const options = {
    weekday: "long",
    year: "numeric",
    timeZoneName: 'short',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }
  
  const readableDate = (dateStr) => {
    const utcDate = new Date(dateStr)
    return utcDate.toLocaleDateString(undefined, options)
  }

  return (
    <div className={auctionCss['qr-container']}>
      <QrConfirmRow message={'Payment added successfully!'} />
      <QrConfirmRow message={'Registration successful!'} />
      <p className={auctionCss['auction-box-info-heading']}>We look forward to seeing you!</p>
      <p className={auctionCss['auction-box-info-text']}>{readableDate(auction.StartTime)}</p>
      <div className={auctionCss['auction-box-info-text']}>
        <p>{auction.OrganizerAddress}<br/>{auction.AuctionLocation}</p>
      </div>
      <div className={auctionCss['auction-box-info-row']}>
        <button className={`${themeCss['button']} ${themeCss['FilledWhite']}`}
          onClick={()=> saveCalInvite(auction)}>
          <img className ={auctionCss['auction-box-info-icons']} src={CalendarIcon} alt='Add To Calendar Icon'/>
          <p className={auctionCss['auction-box-info-link']}>
              Add Event to Calendar</p>
        </button>
      </div>

      <p className={auctionCss['auction-box-info-heading']}>
        Please use this QR code to check in when you arrive at the event.
      </p>
      
      <img className={auctionCss['qr-image']} src={QrCode} alt="qr ticket code" />

      <img className={css['qr-confirm-wallet-button']} src='https://support.apple.com/library/content/dam/edam/applecare/images/en_US/iOS/add-to-apple-wallet-logo.png' alt='Add to Apple Wallet' />
      <GoogleButton />
      
      {/* <img className={css['qr-confirm-wallet-button']} src='https://developers.google.com/static/wallet/images/branding/temp-wallet-condensed.png' alt='Add to Google Wallet' /> */}

      <p> A copy of this QR code has been sent to {email}.</p>
    </div>
  )
}