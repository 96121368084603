import { useState, useEffect} from 'react'


import requireContext from 'require-context.macro'
import { subscribe } from '../api'
import { onUpdateCurrentBidSubscription } from '../graphql/Subscriptions'
import { Link } from 'react-router-dom'
import { useQuery, useQueryClient } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { getAuctionByCode } from '../api'
import { urlQueryParser } from '../util/urlQueryParser'

import css from './sass/itempage.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import ItemBid from './ItemBid'
import ItemInfoBox from './ItemInfoBox'

//temporary fix for images
const images = requireContext('./images', true)
const imageList = images.keys().map(image => images(image))

export default function Item(props) {  
  const [itemData, setItemData] = useState()
  const [needsRefresh, setNeedsRefresh] = useState()
  
  const { auctionId: queryAuctionCode, itemId: queryItemCode } = urlQueryParser(props.location.search)
  const queryClient = useQueryClient()

  

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, queryError, isLoading } = useQuery({
    queryKey: ["auctionDetail", queryAuctionCode],
    queryFn: async () => await getAuctionByCode(queryAuctionCode),
    staleTime: 0
  })
  
  const auctionId = auctionDetail?.AuctionId

  //refresh the auctionDetail data
  if (needsRefresh) {
    let updatedItem = {...itemData, ...needsRefresh}
    queryClient.invalidateQueries(["auctionDetail"])
    setItemData(updatedItem)
    setNeedsRefresh(undefined)
  }

  useEffect(() => {
    showLoading(isLoading)

    if (queryError || (auctionDetail && auctionDetail.queryError)) {
      console.log('Error!', queryError || auctionDetail.queryError)
    }
    else if (auctionDetail) {
      setItemData(auctionDetail.Items.find(item => item.ItemCode === queryItemCode))
    }
  }, [auctionDetail, isLoading, queryError, queryItemCode])

  // this is executed any time a bid is successfully updated
  // use the result to update the current bid for that item on the client
  useEffect(() => {
    if (!auctionId)
      return

    const subCreateFiltered = subscribe({query: onUpdateCurrentBidSubscription, variables: {auctionId}}, {
      next: (result) => {
        console.log('subscription result', result)
        setNeedsRefresh(result.value.data.onUpdateCurrentBid)
      },
      error: (error) => console.error("Subscription error!", error)
    })

    return () => {
      console.log('unsubscribing...')
      subCreateFiltered.unsubscribe()
    }
  }, [auctionId])

  console.log('needsRefresh', needsRefresh)
  if (!itemData) return (<></>)

  //temporary fix for images
  let itemImage = null
  if (auctionDetail) {
    const image = itemData.ImageFilename
    itemImage = imageList.find(imageLink => imageLink.includes(image.substr(0, image.indexOf('.'))))
  }

  return (
    <>
      <div className={css['item-page-path']}>
        <Link className={css['path-text']} to={{ pathname: '/auctionhome', search: `?auctionId=${auctionDetail.AuctionCode}` }}> {auctionDetail.Title} </Link>
        <p className={css['path-dash']}> / </p>
        <Link className={css['path-text']} to={{ pathname: '/auction/item', search: `?auctionId=${auctionDetail.AuctionCode}&itemId=${itemData.ItemCode}` }}>{itemData.Title} </Link>
      </div>
      <div className={`${css['item-container']} ${themeCss['blue-background']}`}>
        <img className={css['item-page-img']} src={itemImage} alt={itemData.Title} />
        <ItemBid item={itemData} outBidNotification={props.location.outbidNotification}/>
        
        <div className={css['item-bottom-info']}>
          <p className={css['item-description']}>
            {itemData.Description}
          </p>
          <ItemInfoBox item={itemData} />
        </div>
      </div>
    </>
  ) 
}