import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { graphql, getUpcomingAuctions, getAuctionDetail } from '../api'

import { getAuctionByIdQuery } from '../graphql/Queries'
import { registerForAuctionMutation, unregisterFromAuctionMutation } from '../graphql/Mutations'

// return all auctions for the current logged in user (to see a different user for dev, hard code the user ID above)
// also return recent auctions
export default function MyAuctions() {
  const [myAuctionList, setMyAuctionList] = useState()
  const [upcomingAuctionList, setUpcomingAuctionList] = useState()
  const [auctionDetail, setAuctionDetail] = useState()

  // my auctionList comes from user now
  // gets my auctions first
  // useEffect(() => {
  //   const getData = async () => {
  //     let response
  //     try {
  //       response = await graphql({query: getAuctionsForUserQuery})
  //     }
  //     catch (e) {
  //       console.log('error', e)
  //     }
  //     console.log('myAuctions response', response)
  //     if (response) setMyAuctionList(response.data.getAuctionsForUser.items)
  //   }
  //   getData()
  // }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const auctions = await getUpcomingAuctions()
        // console.log(auctions)
        const upcomingAuctions = auctions
                                 .sort((a, b) => (a.StartTime < b.StartTime) ? -1 : (a.StartTime > b.StartTime) ? 1 : 0)

        for (const auction of upcomingAuctions) {
          console.log(auction)
          console.log(auction.AuctionId)
          const foundIndex = myAuctionList.findIndex(obj => obj.AuctionId === auction.AuctionId)
          if (foundIndex > -1) {
            auction.registered = true;
            auction.RegistrationId = myAuctionList[foundIndex].RegistrationId
          }
        }

        setUpcomingAuctionList(upcomingAuctions)
      }
      catch (e) {
        console.log('error', e)
      }
    }
    console.log('before getDAta called');
    getData()
  }, [myAuctionList])

  const showAuctionDetailNoStorageMethod = async(auctionId) => {
    if (!auctionId) return
    setAuctionDetail(await graphql({query: getAuctionByIdQuery, variables: {auctionId}}))
  }

  const showAuctionDetailWithStorageMethod = async(auctionId) => {
    // console.log({upcomingAuctionList})
    if (upcomingAuctionList) {
      const detail = await getAuctionDetail(auctionId)
      // console.log(detail)
      setAuctionDetail(detail)
    }
  }

  const registerForAuction = async(auction) => {
    const signUpResponse = await graphql({query: registerForAuctionMutation, variables: {auctionId: auction.AuctionId}})
    if (signUpResponse?.data?.registerUser?.RegistrationId) {
      setAuctionDetail(signUpResponse)
      setMyAuctionList(myAuctionList.concat([{...auction, RegistrationId: signUpResponse.data.registerUser.RegistrationId}]))
    }
  }
  const unregisterFromAuction = async(auction) => {
    console.log(auction);
    console.log('RegistrationId: ', auction.RegistrationId)
    const signUpResponse = await graphql({query: unregisterFromAuctionMutation, variables: {RegistrationId: auction.RegistrationId}})
    if (signUpResponse?.data?.unregisterUser?.RegistrationId) {
      setAuctionDetail(signUpResponse)
      setMyAuctionList(myAuctionList.filter(obj => obj.AuctionId !== auction.AuctionId))
    }
  }

  console.log('my auction', myAuctionList)
  console.log('upcoming', upcomingAuctionList);
  return (
    <>
      <div>
        {auctionDetail &&
          <>
            <p>Response:</p>
            <pre>{JSON.stringify(auctionDetail,0,2)}</pre>
          </>}

        <p><b>My Auctions:</b></p>
        <table border="1">
          <tr>
            <th></th>
            <th>Title</th>
            <th>AuctionCode</th>
            <th>Description</th>
            <th>MinimumRaise</th>
            <th>StartTime</th>
            <th>EndTime</th>
          </tr>
          {myAuctionList && myAuctionList.map((obj) => {
            const auction = (obj.Auction) ? obj.Auction : obj
            console.log('xxxxxxxx')
            console.log(obj)
            console.log(auction);
            return (
              <tr>
                <td>
                  <button onClick={() => unregisterFromAuction(auction)}>
                    Un-Sign Up
                  </button>
                </td>
                <td>{auction.Title}</td>
                <td>
                  <Link  to={{ pathname: '/auctionhome', search: `?auctionId=${auction.AuctionCode}`, auction }}>
                    {auction.AuctionCode}
                  </Link>
                  <br/>
                  <button onClick={() => showAuctionDetailNoStorageMethod(auction.AuctionId)}>
                    Show Detail
                  </button>
                </td>
                <td>{auction.Description}</td>
                <td>{auction.MinimumRaise}</td>
                <td>{new Date(auction.StartTime).toLocaleString()}</td>
                <td>{new Date(auction.EndTime).toLocaleString()}</td>
              </tr>
            )
          })}
        </table>
        <p><b>Upcoming Auctions:</b></p>
        <table border="1">
          <tr>
            <th></th>
            <th>Title</th>
            <th>AuctionCode</th>
            <th>Description</th>
            <th>MinimumRaise</th>
            <th>StartTime</th>
            <th>EndTime</th>
          </tr>
          {upcomingAuctionList && upcomingAuctionList.map((auction) => {
            return (
              <tr>
                <td>
                  {auction.registered ?
                    <button onClick={() => unregisterFromAuction(auction)}>
                      Un-Sign Up
                    </button>
                    :
                    <button onClick={() => registerForAuction(auction)}>
                      Sign Up
                    </button>
                  }
                </td>
                <td>{auction.Title}</td>
                <td>
                  <Link to={{ pathname: '/auctionhome', search: `?auctionId=${auction.AuctionCode}` }}>
                    {auction.AuctionCode}
                  </Link>
                  <br/>
                  <button onClick={() => showAuctionDetailWithStorageMethod(auction.AuctionId)}>
                    Show Detail
                  </button>
                  </td>
                <td>{auction.Description}</td>
                <td>{auction.MinimumRaise}</td>
                <td>{new Date(auction.StartTime).toLocaleString()}</td>
                <td>{new Date(auction.EndTime).toLocaleString()}</td>
              </tr>
            )
          })}
        </table>
      </div>
    </>
  )
}