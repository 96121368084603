import { StyledMenu, StyledNavLink } from 'common/header/MenuStyles'

export default function Menu(props) {

  const auctionsLink = <li><StyledNavLink
                            onClick={props.hideHeaderMenu}
                            className="bold header-links"
                            id="headerAuctionsLink"
                            to="/auctions">MY AUCTIONS</StyledNavLink></li>

  const homeLink = <li><StyledNavLink
                        onClick={props.hideHeaderMenu}
                        className="bold header-links"
                        id="headerHomeLink"
                        to="/">HOME</StyledNavLink></li>

  const userLink = <li><StyledNavLink
                        onClick={props.hideHeaderMenu}
                        className="bold header-links"
                        id="headerUserLink"
                        to="/user/profile">USER</StyledNavLink></li>

  const isAuctionAdmin = localStorage.isAuctionAdmin && !sessionStorage.altCrmId // don't show admin link when browsing as another user
  const adminLink = isAuctionAdmin && <li><StyledNavLink
                                            onClick={props.hideHeaderMenu}
                                            className="bold header-links"
                                            id="headerAdminLink"
                                            to="/admin/linkusertocrm">ADMIN</StyledNavLink></li>

  return (
    <>
      <StyledMenu id="headerLinks">
        {adminLink}
        {auctionsLink}
        {userLink}
        {homeLink}
      </StyledMenu>
    </>
  )
}