import { useState} from 'react'
import { useSelector } from "react-redux"

import EmailPhoneToggle from 'authentication/components/EmailPhoneToggle'
import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { formatPhone } from 'admin/adminutil'

import { startVerify } from './verifyUtils'
import { getAuctionUser } from 'sites/auctions/redux/selectors'

import css from '../sass/flow.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

//for signing user up
import { signUpUserForTextAlerts } from './verifyUtils'

export default function PhoneVerification(props) {
  console.log('prop check', props)

  const [mobileNumber, setMobileNumber] = useState("")
  const [changeNumber, setChangeNumber] = useState(false)

  const auctionUser = useSelector(getAuctionUser)

  const submitInfo = async () => {
    showLoading(true)

    try {
      const codeResponse = await startVerify(mobileNumber)
      // const codeResponse = {success:true}
      console.log({codeResponse})

      if (codeResponse.success) {
        showLoading(false)
        history.push({
          pathname: '/flow/phoneverificationcode',
          mobileNumber,
          auction: props.location.auction
        })
      }
      else {
        // error handling
        console.log(codeResponse)
      }
    }
    catch (e) {
      console.log(e)
    }

    showLoading(false)
  }

  const updatePhone = (phone) => {
    // email toggle strips all non-digits
    console.log({phone})
    setMobileNumber("+1" + phone)
  }

  const submitCurrentNumber = async () => {
    showLoading(true)

    try {
      const signUpResponse = await signUpUserForTextAlerts(props.location.auction, auctionUser, auctionUser.ContactPhone)
      console.log({signUpResponse})
      if (signUpResponse.success) {
        history.push({ pathname: '/auctionhome', search: '?auctionId=' + props.location.auction.AuctionCode })
      }
      else {
        // do error behavior
        console.log({signUpResponse})
      }
      
      showLoading(false)
    }
    catch (e) {
      showLoading(false)
      console.log(e)
    }
  }

  return (
    <div className={css['flow-container-w-margin']}>
      <h2 className={css['centered']}>Complete Phone Verification</h2>

      {!changeNumber && auctionUser.ContactPhone &&
        <>
          <p className={css['centered']}>We will send mobile alerts to: {formatPhone(auctionUser.ContactPhone.replace("+1",""))}.</p>
          <button
            onClick ={() => submitCurrentNumber()}
            className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>Use this number</button>
          <p></p>
          <button
            onClick ={() => setChangeNumber(true)}
            className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>Use a different number</button>
      </>}

      {(changeNumber || !auctionUser.ContactPhone) &&
        <>
          <p className={css['centered']}>Please enter your phone number to receive text alerts.</p>
          <div className={css['flow-inputs']}>
            <label>Phone Number</label>
            <EmailPhoneToggle bgColor={"#FFFFFF"}
              handleChange={updatePhone}
              usernameError={""}
              usernameIsEmail={false}
              hideToggle={true} />
          </div>
          <button
            onClick={() => submitInfo()}
            disabled={mobileNumber.length < 9}
            className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>Verify Number</button>
        </>}
    </div>
  )
}