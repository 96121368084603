import Faq1 from './FAQDescriptionData/Faq1'
import Faq3 from './FAQDescriptionData/Faq3'
import Faq4 from './FAQDescriptionData/Faq4'
import Faq5 from './FAQDescriptionData/Faq5'
import Faq6 from './FAQDescriptionData/Faq6'
import Faq7 from './FAQDescriptionData/Faq7'
import Faq8 from './FAQDescriptionData/Faq8'
import Faq11 from './FAQDescriptionData/Faq11'
import Faq12 from './FAQDescriptionData/Faq12'

const faq2 = "We will contact you within one to three business days."

const faq9 = `Your privacy is important to UCLA, and the registration process will require you to confirm your identity.
When registering, you will be asked to provide an email address or cell phone number,
after which you will receive an email or text message with information that will enable you to complete the process.`

const faq10 = `After you register, you can log in using the email or cell phone and password you used to create your account.
You can also choose to log in through an existing Facebook or Google account.`

export const faqheader=[
  "How do I contact UCLA Donor Relations?",
  "When can I expect a response? ",
  "What is UCLA’s Tax ID number?",
  "How do I know if UCLA has received my gift?",
  "How do I make a new gift?",
  "How do I set-up or adjust an Automatic Pledge Payment (APP)?",
  "Why can’t I see a receipt for my donation?",
  "How do I make a pledge payment via My Giving Gateway?",
  "How do I register for My Giving Gateway?",
  "How do I log in to My Giving Gateway?",
  "Where can I get more information about giving to UCLA?",
  "Important Tax and Other Information"
]

export const faqDescription=[<Faq1/>,faq2,<Faq3/>,<Faq4/>,<Faq5/>,<Faq6/>,<Faq7/>,<Faq8/>,faq9,faq10,<Faq11/>,<Faq12/>]