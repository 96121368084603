import requireContext from 'require-context.macro'

import css from './sass/adminauction.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

// temporary fix for images
const images = requireContext('../auctions/images', true)
const imageList = images.keys().map(image => images(image))

export default function AuctionCard({ auction, state }) {

  //set auction kind to silent for now - paddle to come later
  let kind = 'silent'
  
  //temporary fix for banner image 
  const banner = auction.BannerImageName
  const bannerImage = imageList.find(imageLink => imageLink.includes(banner.substring(0, banner.indexOf('.'))))

  let auctionStateElement = <></>
  if (state === 'ended') auctionStateElement = <p className={css['auction-status-ended']}>AUCTION ENDED</p>
  if (state === 'live') auctionStateElement = <p className={css['auction-status-live']}>AUCTION IS LIVE</p>

  let auctionKindElement = <></>
  if (kind === 'silent') auctionKindElement = <p className={css['auction-status-kind']}>SILENT AUCTION</p>
  if (kind === 'paddle') auctionKindElement = <p className={css['auction-status-kind']}>PADDLE AUCTION</p>


  return (
    <div className={css['auction-card-container']}>
      <img className={css['auction-card-grid-image']} src={`${bannerImage}`} alt={`${auction.Title} banner`} />
      <div className={css['auction-card-grid-status']}>
        {auctionKindElement}
        {auctionStateElement}
        {state === 'live' && <a href={`/admin/liveauctionview?auctionId=${auction.AuctionCode}`} className={css['live-bid-link']}>{'View Live Bidding >'}</a>}
      </div>
      <p className={css['auction-card-grid-title']}>
        {auction.Title}
      </p>

      <div className={css['auction-card-info']}>
        <p>Start Time</p>
        <p>{new Date(auction.StartTime).toLocaleString()}</p>
      </div>

      <div className={css['auction-card-info']}>
        <p>End Time</p>
        <p>{new Date(auction.EndTime).toLocaleString()}</p>
      </div>

      <div className={css['auction-card-info']}>
        <p>Invited / RS</p>
        <p>42/50</p>
      </div>

      <div className={css['auction-card-info']}>
        <p># of Items</p>
        <p>{auction.Items?.length}</p>
      </div>

      <div className={css['auction-card-grid-buttons']}>
        {state === 'ended' && 
          <Link className={`${themeCss['button']} ${themeCss['FilledBlue']}`}
            to={`/admin/${state}auction?auctionId=${auction.AuctionCode}`}>
            {state === 'ended' ? 'Manage Completed Auction' : 'Manage Active Auction'}
          </Link>
        }
        {state === 'live' && 
        <div className={css['auction-card-grid-buttons-live']}>
          <Link className={`${themeCss['button']} ${themeCss['FilledBlue']}`}
            to={`/admin/liveauctionview?auctionId=${auction.AuctionCode}`}>
            View Live Bidding
          </Link>
          <Link className={`${themeCss['button']} ${themeCss['FilledBlue']}`}
            to={`/admin/liveattendeeview?auctionId=${auction.AuctionCode}`}>
            View Attendees
          </Link>
        </div>
        }
      </div>
    </div>
  )
}