import { useState, useEffect } from 'react'
import { useSelector } from "react-redux"

import { showLoading } from 'services/util'

import { getAuctionUser } from '../redux/selectors'
import { updateLiveBid } from '../auctionUtils'

import css from './sass/itembid.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import ItemBidInfo from './ItemBidInfo'
import BidSelector from './BidSelector'
import ConfirmBidModal from '../components/ConfirmBidModal'
import BidSuccessNotification from '../components/BidSuccessNotification'

const successBannerTime = 7

export default function ItemBid({ item, date, outBidNotification }) {

  const [bidAmount, setBidAmount] = useState()
  const [bidResponse, setBidResponse] = useState()
  const [showBidModal, setShowBidModal] = useState(false)
  const [failedBid, setFailedBid] = useState(false)
  const [updatedItem, setUpdatedItem] = useState()
  const [itemBidAmount, setItemBidAmount] = useState()

  const auctionUser = useSelector(getAuctionUser);
  const currentUserId = auctionUser?.UserId
  const isHighBidder = item.CurrentHighBidder === currentUserId

  useEffect(() => {
    if (outBidNotification) {
      setShowBidModal(outBidNotification.showBidModal)
      setBidAmount(outBidNotification.bidAmount)
    }

  }, [outBidNotification])

  const onSubmit = async () => {

    showLoading(true)

    const bidFields = {
      itemId: item.ItemId,
      bidAmount: itemBidAmount,
    }

    try {
      let response = await updateLiveBid(bidFields)
      setBidResponse(response)

      if (response.error) {
        setFailedBid(true)
      } else {
        let newMin = response.CurrentBid + item.MinRaise
        setBidAmount(newMin)
        setShowBidModal(false)
        setFailedBid(false)
        setUpdatedItem({...item, ...response, timeOut: new Date(new Date().getTime() + (successBannerTime * 1000))})
      }
    }
    catch(e) {
      setBidResponse(e)
    }
    showLoading(false)
  }

  //need to wire up auctionStatus to item page for win/currently winning
  const auctionState = 'started'

  return (
    <>
      {updatedItem && <BidSuccessNotification successItem={updatedItem} />}
      <div className={`${css['bid-container']} ${themeCss['shadowed']}`}>
        <p className={css['bid-title']}>{item.Title}</p>
        <ItemBidInfo auctionState={auctionState} isHighBidder={isHighBidder} item={item} />
        <BidSelector item={item} itemBidAmount={itemBidAmount} setItemBidAmount={setItemBidAmount}/>

        <button className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['place-bid-button']}`}
          // onClick={(e) => onSubmit(e)} >
          onClick={() => setShowBidModal(true)} > 
          Place Bid</button>
        {showBidModal && <ConfirmBidModal submitFn={onSubmit} setShowBidModal={setShowBidModal} item={item} bidAmount={itemBidAmount} failedBid={failedBid} /> }

      </div>
    </>
  )
}