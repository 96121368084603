import { history } from 'services/history.js'

import css from '../register/sass/flow.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function PaymentCheck() {

  return (
    <div className={css['flow-container-w-margin']}>
      <h2 className={css['centered']}>Payment Check Router</h2>
      <p className={css['centered']}>Click on the buttons to represent payment validation options</p>
    
      <div className={css['flow-button-container']}>
        <button 
          onClick ={(e) => {history.push({pathname: '/flow/paymentadd' }) }}
          className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>
            No payments on file</button>

        <button 
          onClick ={(e) => {history.push({pathname: '/flow/paymentvalid' }) }}
          className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>
            Payment on File Is Not Expired</button>

        <button 
          onClick ={(e) => {history.push({pathname: '/flow/paymentupdate' }) }}
          className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>
            Payment Needs Updating</button>
      </div>
    </div>
  )
}