import { history } from 'services/history.js'

import css from '../sass/flow.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function EmailLink() {

  return (
    <div className={css['email-container']}>
      <p className={css['email-heading']}>EMAIL CLIENT</p>
      <p className={css['email-text']}>You're invited to an Auction!</p>
      <button 
        onClick ={(e) => {history.push({
          pathname: '/flow/auction',
          search: `?auctionId=abc-xyz`,
        })}}
        className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['email-button']}`}>Go to Auction</button>
    </div>
  )
}