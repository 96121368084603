import { history } from 'services/history.js'

import css from '../sass/flow.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function InviteeOnlyRouter() {

  return (
    <div className={css['flow-container-w-margin']}>
      <h2 className={css['centered']}>Invitee Only Decision Tree</h2>
      <p className={css['centered']}>Click on the buttons to represent invitee only options</p>
    
      <div className={css['flow-button-container']}>
        <button 
          onClick ={(e) => {history.push({pathname: '/flow/inviteeonlypage' }) }}
          className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>
            Invitee Only Auction</button>
        <button 
          onClick ={(e) => {history.push({pathname: '/flow/paymentrouter' }) }}
          className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['centered-button']}`}>
            Auction is NOT invitee only</button>
      </div>
    </div>
  )
}

