import { useEffect, useState } from 'react'
import { useQuery } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { urlQueryParser } from '../util/urlQueryParser'

import { adminGetAuctionByCode } from './auctionsadminutil'

import css from './sass/endedauction.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import LiveAuctionRegistrationRow from './LiveAuctionRegistrationRow'


export default function LiveAttendeeView(props) {
  const [showCheckInFilter, setShowCheckInFilter] = useState(true)
  const [showNotCheckedInFilter, setShowNotCheckedInFilter] = useState(true)
  const [nameFilter, setNameFilter] = useState('')

  //array of truth for all registrations in auction
  const [allRegistrations, setAllRegistrations] = useState([])

  //filtered list based off selection
  const [displayedRegistrations, setDisplayedRegistrations] = useState([])

  const { auctionId: auctionCode } = urlQueryParser(props.location.search)

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["adminAuctionDetail", auctionCode],
    queryFn: async () => {
      return await adminGetAuctionByCode(auctionCode)
    },
    staleTime: 0
  })

  //use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])

  useEffect(() => {
    if (auctionDetail) setAllRegistrations(auctionDetail.Registrations)
  }, [auctionDetail])

  //filter for displayed attendees
  useEffect(() => {

    let filtered = [...allRegistrations]

    if (nameFilter) {
      filtered = allRegistrations?.filter((registration) => {
        console.log(registration)
        return (registration.User.FirstName.includes(nameFilter)) ||
          (registration.User.LastName.includes(nameFilter))
      })
    }
    
    const output = filtered.filter((registration) => {
      return (showCheckInFilter && registration.IsCheckedIn) || (showNotCheckedInFilter && !registration.IsCheckedIn)
    })

    setDisplayedRegistrations(output)

  }, [showNotCheckedInFilter, showCheckInFilter, allRegistrations, nameFilter])

  const resetFilters = () => {
    setShowCheckInFilter(true)
    setShowNotCheckedInFilter(true)
    setNameFilter('')
  }

  if (!auctionDetail) return (<></>)

  // console.log('auction detail', auctionDetail)
  // console.log('admin auction detail', auctionDetail)

  // console.log(displayedRegistrations)



  return (
    <div className={css['ended-auction-container']}>
      

      <h2 id="landingPageTitle">
        {auctionDetail.Title} Registrations
      </h2>
      

      
      <button className={`${themeCss['button']} ${themeCss['FilledBlue']}`}>
        Go to Check-in App
      </button>
      
      <div className={css['ended-auction-attendee-filters']}>
        <div className={css['ended-auction-filter']}>
          <label>
            Attendee Name
          </label>
          <input type='text' value={nameFilter} onChange={(e) => setNameFilter(e.target.value)}/>
        </div>

        <div className={css['ended-auction-filter']}>
          <div className={css['ended-auction-filter-checkbox']}>
            <input type='checkbox' checked={showCheckInFilter} onChange={(e) => setShowCheckInFilter(!showCheckInFilter)} />
            <label> Show Checked in Users</label>
          </div>
          <div className={css['ended-auction-filter-checkbox']}>
            <input type='checkbox' checked={showNotCheckedInFilter} onChange={(e) => setShowNotCheckedInFilter(!showNotCheckedInFilter)} />
            <label> Show Users Not Checked In</label>
          </div>
        </div>

        <button className={`${themeCss['button']} ${themeCss['FilledYellow']}`} onClick={resetFilters}>
          Reset Filters
        </button>
      </div>

      <div className={css['ended-auction-table']}>
        <table>
          <thead>
            <tr className={css['ended-auction-table-header']}>
              <th>Registrant Name</th>
              <th>Checked In?</th>
              <th>Contact Email</th>
              <th>CRM ID</th>
              <th>Contact Phone</th>
            </tr>
          </thead>
          <tbody>
            {displayedRegistrations && displayedRegistrations?.map((registration) => {
            return <LiveAuctionRegistrationRow key={registration.RegistrationId} registration={registration} auctionCode={auctionCode} />
            })}
          </tbody>
          <tfoot>
            <tr className={css['ended-auction-table-footer']}>
              
              
            </tr>
          </tfoot>
        </table>
      </div>
      
    </div>
  )
}