import { history } from 'services/history.js'

import css from '../register/sass/flow.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function PaymentRouter() {

  return (
    <div className={css['flow-container-w-margin']}>
      <h2 className={css['centered']}>Payment Decision Tree</h2>
      <p className={css['centered']}>Click on the buttons to represent payment requirement options</p>
    
      <div className={css['flow-button-container']}>
        <button 
          onClick ={(e) => {history.push({pathname: '/flow/paymentcheck' }) }}
          className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>
            Payment Required</button>

        <button 
          onClick ={(e) => {history.push({pathname: '/flow/qrmock' }) }}
          className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['centered-button']}`}>
            Payment NOT Required</button>
      </div>
    </div>
  )
}