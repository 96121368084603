import { NavLink } from 'react-router-dom'

import { signOut } from 'authentication/signout'

import { MenuPopUpOverlay, MenuPopUp, UserName } from './UserNavigationStyles'

export const doInitials = (loggedInUser) => {

  let userObj= { initials: "", fullname: "" }

  // console.log(loggedInUser)
  if (!loggedInUser)
    return userObj

  const cognitoUser = loggedInUser.cognitoUser ? loggedInUser.cognitoUser.attributes : null
  const crmUser = loggedInUser.crmUser ? loggedInUser.crmUser.profile : null

  // if we have a name from social logon - use that
  if (cognitoUser && cognitoUser.given_name && cognitoUser.family_name) {
    userObj.initials = (cognitoUser.given_name.substr(0, 1) + cognitoUser.family_name.substr(0, 1)).toUpperCase();
    userObj.fullname = cognitoUser.given_name + " " + cognitoUser.family_name;
  }
  // else use name from CRM profile
  else if (crmUser) {
    userObj.initials = crmUser.primaryName.firstName.charAt(0) + crmUser.primaryName.keyName.charAt(0)
    userObj.fullname= crmUser.primaryName.firstName+" "+crmUser.primaryName.keyName
  }
  // else use first letter of email
  else if (cognitoUser && cognitoUser.email) {
    userObj.initials = cognitoUser.email.substr(0, 1).toUpperCase();
  }
  // else use "ME" (non-CRM user who signed up with a phone)
  else {
    userObj.initials = "ME";
  }

  if (cognitoUser && !sessionStorage.altCrmId)
    userObj.username = cognitoUser.email || cognitoUser.phone_number.substr(1) || '';

  return userObj;
}

function UserNavigation (props) {
  const headerInfo = doInitials(props.authenticatedUser);
  // console.log(props.authenticatedUser.crmUser?.creditCards?.length)
  return (
    <MenuPopUpOverlay>
      <MenuPopUp className="has-no-margins">
        <UserName>
          <span id="headerInitials" className="initials bold center">{headerInfo.initials}</span>
          {props.isPhone && (headerInfo.fullname &&
          <span className="user-info-mobile">
            <span className="fullname has-no-margins">{headerInfo.fullname}</span>
            <span className="username has-no-margins">{headerInfo.username}</span>
          </span>
          )}
        </UserName>
        <li className="popup">
          <ul className="has-no-margins">
           {!props.isPhone&& (headerInfo.fullname &&
           <div>
              <li className='fullname '>{headerInfo.fullname}</li>
              <li className='username'>{headerInfo.username}</li>
          {!headerInfo.fullname &&
              <li className='username alone'>{headerInfo.username}</li>}
            </div>)
            }

            <li>
              <NavLink  id="headerMyInformationLink" className="header-links" to="/profile" onClick={props.hideHeaderMenu}>
                My Information
              </NavLink>
            </li>
           
            {props.authenticatedUser.crmUser?.creditCards?.length > 0 &&
              <li className="popup-link">
                <NavLink className="header-links" id="headerPaymentMethodsLink" to="/paymentmethods" onClick={props.hideHeaderMenu}>
                  Payment Methods
                </NavLink>
              </li>
              }

            {props.authenticatedUser.cognitoUser.attributes && !props.authenticatedUser.cognitoUser.attributes.identities && /* for now only social users have identities, they can't change their passwords */
              <li className="change-password popup-link"><NavLink id="headerChangePasswordLink" className="header-links" onClick={props.changePassword} to="/resetpasswordfinish">Change Password</NavLink></li>}

            <li className="signout ">
              <button id="headerSignoutLink" className="header-links" onClick={(e) => {
                signOut()
              }}>
                Sign Out
              </button>
            </li>
          </ul>
        </li>
      </MenuPopUp>
    </MenuPopUpOverlay>
  )
}

export default UserNavigation
