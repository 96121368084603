// Places a live bid
export const updateCurrentBidMutation = `mutation updateCurrentBid($bidAmount: Int!, $itemId: String!) {
  updateCurrentBid(input: {
    BidAmount: $bidAmount,
    ItemId: $itemId,
    UserId: "{UserIdFromToken}",
    FullName: "{FullNameFromToken}",
  }) {
    CurrentBid
    BidCount
    CurrentHighBidder
    ItemId
    AuctionId
  }
}`
export const registerForAuctionMutation = `mutation registerForAuction($auctionId: String!, $textAlertNumber: String, $isCheckedIn: Boolean) {
  registerUser(input: {AuctionId: $auctionId, UserId: "{UserIdFromToken}", TextAlertNumber: $textAlertNumber, IsCheckedIn: $isCheckedIn}) {
    RegistrationId
    EventHubRegistrationId
    PdfBase64
    PdfUrl
    QrBase64
    ResponseData
  }
}`
export const updateUserRegistrationMutation = `mutation updateUserRegistration($auctionId: String!, $textAlertNumber: String, $isCheckedIn: Boolean) {
  updateUserRegistration(input: {AuctionId: $auctionId, UserId: "{UserIdFromToken}", TextAlertNumber: $textAlertNumber, IsCheckedIn: $isCheckedIn}) {
    RegistrationId
    EventHubRegistrationId
    ResponseData
  }
}`
export const unregisterFromAuctionMutation = `mutation unregisterFromAuction($registrationId: String!) {
  unregisterUser(input: {RegistrationId: $registrationId}) {
    RegistrationId
    EventHubRegistrationId
    ResponseData
  }
}`

// Creates entries in auctions-cognito-login-mapping and auctions-users
export const createUserMutation = `mutation createUser {
  createUser {
    UserId
  }
}`
// Updates an auctions user after creation or sign in (back end checks CRM every time)
export const updateUserMutation = `mutation updateUser($firstName: String, $lastName: String, $phoneNumber: String, $emailAddress: String, $ccToken: String, $userData: AWSJSON) {
  updateUser(input: {
    UserId: "{UserIdFromToken}",
    CrmId: "{CrmIdFromToken}",
    FirstName: $firstName,
    LastName: $lastName,
    ContactPhone: $phoneNumber,
    ContactEmail: $emailAddress,
    CreditCardToken: $ccToken,
    UserData: $userData
  }) {
    UserId
    CrmId
    FirstName
    LastName
    ContactEmail
    ContactPhone
    CreditCardToken
    UserData
  }
}`
// Deletes all entries in auctions-cognito-login-mapping and the entry in auctions-users
export const deleteUserMutation = `mutation deleteUser($cognitoLogin: String!) {
  deleteUser(input: {CognitoLogin: $cognitoLogin}) {
    UserId
    UserData
  }
}`

export const createAuctionMutation = `mutation createAuction($input: CreateAuctionInput!) {
  createAuction(input: $input) {
    AuctionId
  }
}`
export const updateAuctionMutation = `mutation udpateAuction($input: UpdateAuctionInput!) {
  updateAuction(input: $input) {
    AuctionId
    ResponseData
  }
}`
export const deleteAuctionMutation = `mutation deleteAuction($auctionId: String!) {
  deleteAuction(input: { AuctionId: $auctionId }) {
    AuctionId
    ResponseData
  }
}`
export const createAuctionItemMutation = `mutation createAuctionItem($input: CreateAuctionItemInput!) {
  createAuctionItem(input: $input) {
    ItemId
  }
}`
export const updateAuctionItemMutation = `mutation updateAuctionItem($input: UpdateAuctionItemInput!) {
  updateAuctionItem(input: $input) {
    ItemId
  }
}`
export const deleteAuctionItemMutation = `mutation deleteAuctionItem($itemId: String!) {
  deleteAuctionItem(input: { ItemId: $itemId }) {
    ItemId
  }
}`

export const savePaymentMethodMutation = `mutation savePaymentMethod($nonce: String!)  {
  savePaymentMethod(input: {Nonce: $nonce}) {
    Success
    ResponseData
  }
}`

