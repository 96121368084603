import { useState, useEffect } from 'react'
import { useQuery, useQueryClient } from "@tanstack/react-query"

import { showLoading } from 'services/util'
import { getAuctionByCode } from '../api'

import css from './sass/adminitem.module.scss'
import adminCss from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import ItemDisplayRow from './ItemDisplayRow'

import CreateItem from './CreateItem'

export default function ItemDisplay( {selectedAuction } ) {

  const [invalidateItemQuery, setInvalidateItemQuery] = useState(false)
  const [showItemModal, setShowItemModal] = useState(false)

  const auctionCode = selectedAuction.AuctionCode
  const queryClient = useQueryClient()

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["auctionDetail", auctionCode],
    queryFn: async () => await getAuctionByCode(auctionCode),
    staleTime: 0
  })

  useEffect(() => {
    if (invalidateItemQuery) {
      queryClient.invalidateQueries(["auctionDetail", auctionCode])
      setInvalidateItemQuery(false)
    }
  }, [invalidateItemQuery])

  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }
  }, [auctionDetail, error, isLoading])

  const showCreateItemModal = () => {
    let newValue = !showItemModal
    setShowItemModal(newValue)
  }
  
  // maybe provide a link for if no auction is loaded to reroute to load auctions - 404 something
  if (!auctionDetail) return (<></>)
  
  return (
    <>
      <div className={css['admin-items-container']}>
        <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`} onClick={()=> showCreateItemModal()} >
          Add Item 
        </button>
        {auctionDetail?.Items && auctionDetail.Items.map((item) => {
          return (
            <ItemDisplayRow key={item.ItemId} item={item} setInvalidateItemQuery={setInvalidateItemQuery} />
            )
          })}
        {auctionDetail.Items.length === 0 && <p>No Items, add some!</p>}
      </div>
      {showItemModal && 
        <>
          <div className={themeCss['modal-background']} onClick={()=>showCreateItemModal()}></div>
          <div className={adminCss['auction-modal']}>
            <button className={`${themeCss['button']} ${themeCss['FilledYellow']} ${themeCss['centered-button']}`} onClick={()=>showCreateItemModal()} >Close Modal</button>
            <CreateItem setInvalidateItemQuery={setInvalidateItemQuery} auction={selectedAuction}/>
          </div>
        </>
      }
    </>
  )
}