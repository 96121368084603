import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { showLoading } from 'services/util';

export default function VerifyPhoneNumberOrEmail() {
  const [firstResult, setFirstResult] = useState()
  const [secondResult, setSecondResult] = useState()
  const [thirdResult, setThirdResult] = useState()
  const [verificationCode, setVerificationCode] = useState()
  const [contact, setContact] = useState()

  const startVerify = async () => {
    showLoading(true)

    let user = await Auth.currentAuthenticatedUser()

    const updateObj = (contact.indexOf('@') > 0) ? { email: contact, } : { phone_number: contact, }

    const firstResponse = await Auth.updateUserAttributes(user, updateObj);
    setFirstResult(firstResponse)

    const secondResponse = await Auth.verifyCurrentUserAttribute(Object.keys(updateObj)[0])
    setSecondResult(secondResponse)

    showLoading(false)
  }

  const submitCode = async () => {
    showLoading(true)

    const response = await Auth.verifyCurrentUserAttributeSubmit((contact.indexOf('@') > 0) ? "email" : "phone_number", verificationCode)

    setThirdResult(response)

    showLoading(false)
  }

  return (
    <>
      <div style={{padding: "20px"}}>
        Mobile number or email to add to your account and verify: <input type="text" onChange={e => setContact(e.target.value)} value={contact}/>
        &nbsp;<button onClick={startVerify}>Verify</button>
        <p />
        First result: {firstResult && <div style={{border: "black 1px solid"}}><pre>{JSON.stringify(firstResult,0,2)}</pre></div>}
        <p />
        Second result: {secondResult && <div style={{border: "black 1px solid"}}><pre>{JSON.stringify(secondResult,0,2)}</pre></div>}

        <p />
        Submit Code: <input type="text" onChange={e => setVerificationCode(e.target.value)} value={verificationCode}/>
         &nbsp;<button onClick={submitCode}>Submit verification code</button>
        <p />
        Third result: {thirdResult && <div style={{border: "black 1px solid"}}><pre>{JSON.stringify(thirdResult,0,2)}</pre></div>}
      </div>
    </>
  )
}