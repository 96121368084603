import { history } from 'services/history.js'

import css from '../payment/sass/payment.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import FakeQrCard from '../register/flow/FakeQrCard'

const fakeAuction = {
  "AuctionCode": "def-xyz",
  "AuctionId": "2d7c458a-6216-11ee-8c99-0242ac120002",
  "AuctionLocation": "Los Angeles, CA 90038",
  "BannerImageName": "banner-music.jpg",
  "Description": "Auction held by the music department, to promote musical enjoyment for college students.",
  "EndTime": "2024-05-22T01:00:00.000Z",
  "MinimumRaise": 10,
  "OrganizerAddress": "4871 Westwood Blvd",
  "OrganizerLogoName": "logo-music.jpg",
  "OrganizerName": "Music Department",
  "OrganizerWebsite": "www.ucla.edu",
  "StartTime": "2024-04-21T03:00:00.000Z",
  "Title": "Los Angeles Opera"
}

export default function FakePaymentSuccess() {

//   const auctionCode = localStorage.getItem('savedAuctionCode')
// console.log(auctionCode)

//   // get the auctionUser from local storage
//   //find the auction based off the auctionCode that was saved
//   const auctionUser = useSelector(getAuctionUser);
//   console.log(auctionUser)


//   //maybe just query?
//   const userAuctions = auctionUser.MyAuctions
//   const findCurrentAuction = (auction) => auction.Auction.AuctionCode === auctionCode
//   const currentIndex = userAuctions.findIndex(findCurrentAuction)
//   const currentAuction = userAuctions[currentIndex].Auction

  return (
    <div className={themeCss['blue-background']}>
     <div className={css['payment-container']}>
      {/* <h2 className={css['payment-header-white']}>Successfully added payment!</h2> */}
      
      {/* <img src={checkmark} alt={'Payment Successful'} className={css['payment-confirm']} /> */}
      
      {fakeAuction &&
      <FakeQrCard auction={fakeAuction} auctionState={'before'} checkedIn={'false'} />
    }

      <button 
        onClick ={() => {history.push({pathname: '/auctionhome',
        search: `?auctionId=${fakeAuction.AuctionCode}`, }) }}
        className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['auction-button']}`}>
          Go To Auction </button>
    </div>
    </div>
  )
}