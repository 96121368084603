
//////////////////////////////////////////////////////////////////////////////////////
// IMPORTANT: This component only deletes the Auction User.
//            It does not delete the CIDM user.
//            Only MGG admin can do that.
//////////////////////////////////////////////////////////////////////////////////////

import { Component } from 'react'
import { withRouter } from "react-router-dom"
import styled from 'styled-components'

import { showLoading } from 'services/util'
import { getCognitoUsers } from "admin/adminapi"
import { adminErrorHandler } from 'admin/adminutil'
import LinkUserWrapper from 'admin/linkuser/LinkUserWrapper'
import UserProfile from 'admin/linkuser/UserProfile'
import LinkUserForm from 'admin/linkuser/LinkUserForm'
import Alert from 'common/notifications/Alert'
import DeleteConfirmModal from 'common/ConfirmModal'
import UserProfileInfopopup from 'admin/linkuser/UserProfileInfopopup'
import * as colors from 'css/Colors'
import { FilledBlue } from 'css/Buttons'

import iconInfo from 'images/iconColorinfo.svg'
import AdminDeleteIcon from 'images/x-red.svg'

import { deleteAuctionUser } from "./auctionsadminutil"

const defaultFontSize = 16

const ModalInner = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  img {
    height: 40px;
    width: 40px;
    padding: 0 10px;
  }
  .modal-text {
    padding: 0 10px;
    p {
      margin: 0;
      max-width: 450px;
    }
  }
`
const UserInformation = styled.div`
  color:#fff;
  padding:20px;
  border-radius:5px;
  background-color:${ props => props.crm ? `${colors.secondaryMediumBlue}` : `${colors.primaryBlue}`};
  /* min-height:${315/defaultFontSize}rem; */
  h6 {
    color:#fff;
    margin: 0 0 ${18/defaultFontSize}rem;
  }
  .user-search-form {
    margin-left: 0;
  }
  .user-search-form {
    margin: 0;
  }
  .crm-user-information{
    background-color: #FFFFC3;
  }
  .smaller-alert>div>div {
    padding: 0 calc(10rem/16);
  }
  .mismatch-error{
    color:#000000;

  }
  .link-donor-info-block-title{
    font-size:${16/defaultFontSize}rem;
  }
  .account-display{
    font-size:${14/defaultFontSize}rem;
  }

  .alert>div>div {
    padding-left: 0;
  }
`
const SubmitButtons = styled.div`
  text-align:center;
  margin:${30 / defaultFontSize}rem;
  & > div {
    display: inline-block;
  }
  a {
    text-decoration: none;
  }
`

class DeleteAuctionUser extends Component {

  state = {
    cognitoLookup: '',
    cognitoLookupResponse: '',
    deleteUserResponse: '',

    username: null,
    isClean: true
  }

  handleCognitoSubmit = async e => {
    e.preventDefault()

    showLoading(true)

    const data = new FormData(e.target)

    let lookup = data.get('username').trim()

    if (lookup && lookup.charAt(0) === '+')
      lookup = lookup.replace(/\D/g,'')

    const response = await getCognitoUsers(lookup)

    // Set username-to-be-deleted if accounts found
    // Sets first found email/phone num because all accs with username are deleted, may revisit
    if (response.length) {
      this.setState({
        username: response[0].Attributes.email || response[0].Attributes.phone_number
      })
    }
    this.setState({
      cognitoLookupResponse: response,
      searchFoundUsers: response.length
    })

    showLoading(false)
  }

  handleCognitoChange = e => {
    this.setState({
      cognitoLookup: e.target.value.trim(),
      cognitoLookupResponse:'',
      searchFoundUsers:'',
      username: null,
      isClean: true
    })
  }

  handleDeleteUser = async e => {
    e.preventDefault()
    showLoading(true)

    const username = this.state.username
    const response = await deleteAuctionUser(username)
    this.setState({
      deleteUserResponse: response,
      cognitoLookupResponse:'',
      searchFoundUsers:'',
      username: null,
      isClean: true
    })
    // console.log(response)

    showLoading(false)
  }


  render() {
    let userProfile = this.state.searchFoundUsers&&this.state.cognitoLookupResponse.map((user,index)=>{
      return <UserProfile
        key={index}
        value={index}
        user={user}
        numUsers={this.state.searchFoundUsers}
        selected={true}
        handleSelect={() => {}}
      />
    })

    return (
      <>
        {!this.state.isClean &&
          <DeleteConfirmModal
            onConfirm={this.handleDeleteUser}
            onCancel={() => {this.setState({isClean: true})}}
            cancelText={"CANCEL"}
            confirmText={"OK"}
          >
            <ModalInner>
              <div><img src={AdminDeleteIcon} alt='Delete User icon'/></div>
              <div className="modal-text">
                <p className="bold">Confirm Auctions User Account Deletion?</p>
                <p>Are you sure you want to this user from the Auctions Application?<br></br>
                  <span>Note: this will not affect the user’s other UCLA logins (My Giving Gateway, Alumni Portal, UCLA One, etc.)
                        or their CRM Record in Blackbaud.</span>
                </p>
              </div>
            </ModalInner>
          </DeleteConfirmModal>
        }

        <LinkUserWrapper>
          <h2>Delete Auctions User</h2>

          {this.state.deleteUserResponse.error &&
            <Alert
              className="smaller-alert"
              nonPromoPage
              icon={iconInfo}
              color={colors.messagingRed}
              width="25" height="25" top="35%" >
              <div className="mismatch-error">Could not delete user. Error: "{this.state.deleteUserResponse.error.msg}"</div>
            </Alert>
          }

          <div className=" grid">
            <div className="span-12">
              <UserInformation>
                <LinkUserForm
                  title={"Auction User's Information"}
                  inputName={"userId"}
                  btnName={"cognitoSearch"}
                  inputTitle={"Enter the Email/Phone Number that the Auction User uses to login"}
                  formType="user"
                  onSubmit={this.handleCognitoSubmit}
                  onChange={this.handleCognitoChange}
                >
                  <UserProfileInfopopup/>
                </LinkUserForm>

              {this.state.cognitoLookupResponse && (this.state.searchFoundUsers
                ?
                <div className="account-display">
                  <p> <strong>{this.state.searchFoundUsers} Account{this.state.searchFoundUsers !== 1 ? 's' : ''} found</strong> <br/>
                  {this.state.searchFoundUsers > 1&& <em>note: multiple linked accounts will all be deleted</em>} </p>
                  {userProfile}
                </div>
                :
                <Alert
                  className="alert"
                  padded
                  nonPromoPage
                  icon={iconInfo}
                  color={colors.messagingRed}
                  width="25" height="25" top="35%" >
                  <div className="mismatch-error">{adminErrorHandler(this.state.cognitoLookupResponse.error, `No Account found with username "${this.state.cognitoLookup}". Please check the username and try again.`)}</div>
                </Alert>
              )}
              </UserInformation>
            </div>
          </div>

          <SubmitButtons>
            <div>
              <FilledBlue name="deleteUser"
                disabled={!this.state.username || !this.state.searchFoundUsers}
                onClick={() => {this.setState({isClean: false})}}>
                <span> DELETE USER</span>
              </FilledBlue>
            </div>
          </SubmitButtons>

          <br/>
          <br/>
          <br/>

        </LinkUserWrapper>
      </>
    )
  }
}

export default withRouter(DeleteAuctionUser)
