import { Route } from 'react-router-dom';

import Menu from './components/Menu'
import OlgHeader from './components/OlgHeader'
import OlgContinueAsGuest from './components/OlgContinueAsGuest'
import Login from 'authentication/routes/Login';
import logoImage from './images/giveto_logo.png';

import PromoPage from '../mygiving/promopage/PromoPage';

const cognitoClientIds = {
  // "dev": "384d9lths2onj01k9l51b0rs8g",
  // "stage": "3u2eganevicr5924qv28ssuhjv",
  "pilot": "2820di1drd0674e00mr5b6pfgn",
  "preprod": "ll69i9gu6r986k6ubbfa578dq",
  "prod": "5kkqu2r85eh01fu4fvh8sht8dg",
}

const hideFooter = () => {
  return true
}

const themeHeader = (<OlgHeader />)

const nonCrmUserExtra = (<OlgContinueAsGuest />)

const hideHeader = () => {
  return (window.location.href.indexOf('/profile') > -1)
}

const routes = [
  <Route exact={true} path='/' component={Login} key="/olghome" />,
  <Route exact path='/promopage' component={PromoPage} key='/promopage' />,
]

// olg inherits from mygiving and is inhertied by olgalumni
export const olg = {
  name: 'olg',
  apiGatewayHost: process.env.REACT_APP_IDM_API_GATEWAY_HOST,
  routes,
  logoImage,
  themeMenu: Menu,
  themeHeader,
  nonCrmUserExtra,
  hideHeader,
  hideFooter,
  closeWindowOnProfileSave: true,
  cognitoClientId: cognitoClientIds[process.env.REACT_APP_AWS_ENVIRONMENT] || cognitoClientIds["pilot"],
  extraGetUserParam: '?app=olg',
  setSsoCookieForCrmUser: true,
  setSsoCookieForNonCrmUser: false,
  closeAfterSignin: true,
  forceLogoutOnSignOrRegister: true,
  captureNonCrmUser: true,
}

