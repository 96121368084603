import { useEffect, useState, useMemo } from "react"
import { getAuctionStatus, hourTimer } from "../util/dateString"

export default function AuctionStatus( {auction, updateStatus, updateCountdown, forcedEnd} ) {
  const [date, setDate] = useState()
  
  useEffect(() => {

    if (forcedEnd) {
      if (updateStatus) updateStatus('ended')
      return
    }

    if (auction) {
      const status = getAuctionStatus(date, auction.StartTime, auction.EndTime) 
      if (updateStatus) updateStatus(status)
      if (updateCountdown) {
        if (status === 'before') updateCountdown(hourTimer(date, auction?.StartTime))
        if (status === 'started') updateCountdown(hourTimer(date, auction?.EndTime))
      }
    }

    const timer = setInterval(() => setDate(new Date()), 100)

    return function cleanup() {
      clearInterval(timer)
    }
  }, [auction, date])

  return (
    <>
    </>
  )
}
