import BraintreeWebClient from 'braintree-web/client'

import { graphql, getAuctionUserApi } from '../api'
import { getBraintreeClientTokenQuery } from '../graphql/Queries'
import { savePaymentMethodMutation } from '../graphql/Mutations'

const getBraintreeClientToken = async (flushToken) => {
  if (!flushToken && localStorage.getItem('braintreeClientToken')) {
    return localStorage.getItem('braintreeClientToken')
  }
  else {
    try {
      const response = await graphql({query: getBraintreeClientTokenQuery})
      if (!response?.data?.getBraintreeClientToken?.ClientToken) {
        console.error("No braintree client token returned: ", response)
      }
      else {
        const newToken = response.data.getBraintreeClientToken.ClientToken
        localStorage.setItem('braintreeClientToken', newToken)
        return newToken
      }
    }
    catch (e) {
      console.error("error in getBraintreeClientToken:", e)
      return e
    }
  }
};

export const getBraintreeClientInstance = async () => {
  const braintreeClientToken = await getBraintreeClientToken()

  let newClient
  try {
    newClient = await BraintreeWebClient.create({authorization: braintreeClientToken})
  }
  catch (err) {
    // get fresh token in case it was expired
    console.log(err)
    const newToken = await getBraintreeClientToken(true)
    newClient = await BraintreeWebClient.create({authorization: newToken})
  }

  return newClient;
};

export const savePaymentMethod = async (nonce) => {
  try {
    console.log('nonce', nonce)
    const response = await graphql({query: savePaymentMethodMutation, variables: {nonce}})
    if (!response?.data?.savePaymentMethod?.Success) {
      console.error("Save credit card failed: ", response)
      return {error: {msg: "Save credit card failed", key: "SAVE_CREDIT_CARD_FAILED", response} }
    }
    else {
      // updates auctionUser in redux/localStorage
      await getAuctionUserApi()
      return response?.data?.savePaymentMethod
    }
  }
  catch (e) {
    console.error("error in savePaymentMethod:", e)
    return {error: {msg: "error in savePaymentMethod", key: "SEVERE_ERROR", e} }
  }
};