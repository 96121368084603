import { history } from 'services/history.js'

// import css from './sass/myitems.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function MyEmptyItems({auctionDetail}) {

  return (
      <div>
        <h2>No current high bids</h2>
        <p>Go to the auction to bid on items!</p>
        <p>When you are the high bidder on an item in the auction, more information will be displayed here</p>
        <button 
          onClick ={(e) => {history.push({
            pathname: '/auction/myitems',
            search: `?auctionId=${auctionDetail.AuctionCode}`,
            auctionDetail
          })}}
          className={`${themeCss['FilledBlue']} ${themeCss['button']}`}>
          Go to auction
        </button>
      </div>
  )
}