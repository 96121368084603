// as far as I can tell, app-sync/dynamo has no way to say "give me the 5 next upcoming auctions"
// so we retrieve all upcoming auctions and sort/filter on the client side
export const listUpcomingAuctionsQuery = `query listUpcomingAuctions {
  listAuctions(filter: {EndTime: {ge:"${new Date().toISOString()}"}}) {
    items {
      AuctionCode
      AuctionId
      AuctionLocation
      BannerImageName
      Description
      EndTime
      MinimumRaise
      OrganizerAddress
      OrganizerLogoName
      OrganizerName
      OrganizerWebsite
      StartTime
      Title
    }
  }
}`

// to pull all the auctions in the list, pulling from a time before auctions were created
export const listAllAuctionsQuery = `query listUpcomingAuctions {
  listAuctions(filter: {EndTime: {ge:"2023-01-01T01:33:42.551Z"}}) {
    items {
      AuctionCode
      AuctionId
      AuctionLocation
      BannerImageName
      Description
      EndTime
      MinimumRaise
      OrganizerAddress
      OrganizerLogoName
      OrganizerName
      OrganizerWebsite
      StartTime
      Title
    }
  }
}`

// gets UserId from CognitoLogin, which it gets from the user's ID token
export const getAuctionUserQuery = `query getUser {
  getUser {
    CreditCardToken
    CrmId
    FirstName
    LastName
    ContactPhone
    ContactEmail
    UserId
    PaymentMethods {
      CardType,
      NameOnCard,
      Last4,
      Expires,
      DateAdded,
    }
    MyAuctions {
      RegistrationId
      AuctionId
      TextAlertNumber
      IsCheckedIn
      QrBase64
      Auction {
        AuctionCode
        AuctionId
        AuctionLocation
        BannerImageName
        Description
        EndTime
        Title
        StartTime
        OrganizerWebsite
        OrganizerName
        OrganizerLogoName
        OrganizerAddress
        MinimumRaise
      }
    }
  }
}`

// gets UserId from CognitoLogin, which it gets from the user's ID token
export const getBraintreeClientTokenQuery = `query getBraintreeClientToken {
  getBraintreeClientToken {
    ClientToken
    ResponseData
  }
}`

const getCommonQueryFinish = (auctionAdminData, itemAdminData) => `
  Items {
      Donor
      ImageFilename
      MinRaise
      StartingBid
      Value
      Title
      ItemId
      CurrentHighBidder
      ItemCode
      Description
      CurrentBid
      BidCount
      AuctionId
      CurrentHighBidder
      Paid
      ${itemAdminData ? itemAdminData : ""}
    }
    EndTime
    Description
    BannerImageName
    AuctionLocation
    AuctionId
    AuctionCode
    Title
    StartTime
    OrganizerWebsite
    OrganizerName
    OrganizerLogoName
    OrganizerAddress
    MinimumRaise
    Bids {
      BidAmount
      ItemId
      FullName
      Timestamp
      UserId
    }
    ${auctionAdminData ? auctionAdminData : ""}
  }
}`

export const getAuctionByIdQuery = `query getAuctionById($auctionId: String!) {
  getAuction(AuctionId: $auctionId) {`
  + getCommonQueryFinish()

export const getAuctionByCodeQuery = `query getAuctionByCode($auctionCode: String!) {
  getAuctionByCode(AuctionCode: $auctionCode) {`
  + getCommonQueryFinish()


///////////////////////////////////////////////////////////////////////////////////////
/// Admin only queries ////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

export const adminGetAuctionImagePresignedUrlQuery = `query adminGetAuctionImagePresignedUrl($auctionCode: String!, $itemId: String!, $fileName: String!, $contentType: String!) {
  getPresignedUrlForUpload(AuctionCode: $auctionCode, ItemId: $itemId, FileName: $fileName, ContentType: $contentType) {
    PresignedUrl
    ResponseData
  }
}`

// used by admin user to get more info on high bidders, will not work if called by non-admin user
export const adminGetAuctionByCodeQuery = `query adminGetAuctionByCode($auctionCode: String!) {
  adminGetAuctionByCode(AuctionCode: $auctionCode) {`
  + getCommonQueryFinish(`EventHubEventId
                          EventHubRegistrations {
                            RegistrationId
                            RegistrationStatus
                          }
                          Registrations {
                            RegistrationId
                            EventHubRegistrationId
                            IsCheckedIn
                            User {
                              ContactEmail
                              CrmId
                              ContactPhone
                              FirstName
                              LastName
                            }
                          }`,
                         `CurrentHighBidderInfo {
                            ContactEmail
                            ContactPhone
                            CrmId
                            FirstName
                            LastName
                         }`)

