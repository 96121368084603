import React from 'react'

export default function Home() {

  return (
    <>
      <div style={{padding: "20px"}}>
        <h1 id="landingPageTitle">Home</h1>

        <p><a href="/testing">Dead page for login testing</a></p>
        <p><a href="/myauctions">My Auctions demo</a></p>
        <p><a href="/livebids">Live bidding demo</a></p>
        <p><a href="/verify">Verify phone number or email</a></p>
        <p><a href="/flow/auction?auctionId=def-xyz&publicId=42EDE97E-3767-49F7-96CE-998DCC331CA7&email=test@test.com">Deep link to auction example (as in user clicking link from email)</a></p>
        <p><a href="http://localhost:3000/flow/auction?auctionId=def-xyz&publicId=3b2eeb16-4839-4c8e-9e7c-621f4e3ebc2a&email=msavino@support.ucla.edu">Another Deep link to auction example (as in user clicking link from email)</a></p>
        <p><a href="/flow/auction?auctionId=def-xyz&publicId=42EDE97E-3767-49F7-96CE-998DCC331CA7&email=dp.no.crm@gmail.com">Deep link to auction example (user is not found in Cognito)</a></p>
        <p><a href="/flow/emaillink">Sign In / Up Flow</a></p>
        <p><b>Admin pages</b></p>
        <p><a href="/admin/liveauctionview?auctionId=def-xyz">Admin live auction view</a></p>
        <p><a href="/admin/items">Add Item</a></p>
        <p><a href="/admin/auctions">Add Auction</a></p>
      </div>
    </>
  )
}

// https://pilot.auctions.support2.ucla.edu/flow/auction?auctionId=def-xyz&publicId=3b2eeb16-4839-4c8e-9e7c-621f4e3ebc2a&email=msavino@support.ucla.edu
// https://pilot.auctions.support2.ucla.edu/flow/auction?auctionId=def-xyz&publicId=8d55a6aa-c871-4318-b563-ba853c57d22d&email=yma@support.ucla.edu
// https://pilot.auctions.support2.ucla.edu/flow/auction?auctionId=def-xyz&publicId=11504a2b-59a7-4fee-8231-66f22b0001b9&email=esullivan@support.ucla.edu
// https://pilot.auctions.support2.ucla.edu/flow/auction?auctionId=def-xyz&publicId=d019e536-a78b-4e5b-971f-c0f94fddd468&email=mschoeppel@support.ucla.edu
