import { useState, useEffect, useContext } from 'react'
import { useSelector } from "react-redux"

import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { ThemeContext } from 'ThemeContext'
import { urlQueryParser } from 'sites/auctions/util/urlQueryParser'

import { getAuctionUser } from '../redux/selectors'
import { registerUserForAuction } from '../api'

import css from './sass/payment.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import { validCardNumber, validExp } from '../register/flow/utils'

import { updateAuctionUser } from 'sites/auctions/api'
import {getBraintreeClientInstance, savePaymentMethod} from './paymentapi'
import UpdatePaymentBraintree from './UpdatePaymentBraintree'

export default function EnterPayment( {header, message, buttonText, checkout, user} ) {

  const [name, setName] = useState()
  const [number, setNumber] = useState()
  const [exp, setExp] = useState()
  const [cvv, setCvv] = useState()
  const [zip, setZip] = useState()
  const [clientInstance, setClientInstance] = useState()
  const [loadError, setLoadError] = useState('')
  const [validationErrors, setValidationErrors] = useState([])
  const auctionUser = useSelector(getAuctionUser)

  const theme = useContext(ThemeContext)

  useEffect(() => {
    const formLoadError = 'Oops something went wrong. Please try again or contact Donor Relations support at ' + theme.supportPhone + '.';
    showLoading(true)

    async function setup() {
      try {
        setClientInstance(await getBraintreeClientInstance())
        showLoading(false)
      }
      catch (e) {
        setLoadError(formLoadError)
        console.error({msg: 'Error loading payment form.', e})
        showLoading(false)
      }
    }

    if (sessionStorage.altCrmId)  {
      setLoadError("Entering a donor's credit card information in Browse as User mode is not allowed due to PCI compliance.")
      showLoading(false)
    }
    else {
      setup()
    }

    return () => {
      setClientInstance();
      // setLoadError(formLoadError)
    }
  }, [theme])


  useEffect(() => {
    const isCardValid = validCardNumber(number)
    const isExpValid = validExp(exp)
    let validCheck = []
  
    if (isCardValid.length) {
      validCheck = [...validCheck, ...isCardValid]
    }
    if (isExpValid.length) {
      validCheck = [...validCheck, ...isExpValid]
    }
    setValidationErrors(validCheck)

  }, [number, exp])

  const submitCCAdd = async (e) => {

    e.preventDefault()
    console.log("submitCCAdd called")

    if (validationErrors.length > 0) return

    showLoading(true)
    setLoadError('')

    const ccToken = `${number.slice(-4)}-${exp}`

    const userFields = {
      ccToken
    }
    try {
      const response = await updateAuctionUser(userFields)
      console.log(response)

      const savedAuctionCode = localStorage.getItem('savedAuctionCode')
      if (savedAuctionCode) {
        const registerResponse = await registerUserForAuction(savedAuctionCode, auctionUser)
        console.log(registerResponse)
      }

      showLoading(false)
      
      //reroute statements
      // to checkout page for that auction, with updated token
      if (checkout) {
        history.push({
          pathname: '/checkout',
          search: `?auctionId=${checkout}`
        })
      }
      if (user) {
        history.push('/user/profileupdate')
      }
      // to add payment page on successful update
      // if it is not in the checkout flow
      else history.push('/flow/paymentsuccess')
      
    }
    catch(e) {
      setLoadError('There was an error adding your credit card, please try again or contact support at ' + theme.supportEmail + '.')
      showLoading(false)
    }
  }

  console.log('checkout, ', checkout)

  // need method to load Card for load card variable
  const loadCard = {}

  // need updated method for auction app structure - copied from MGG
  const updateLoggedInUser = (newCard) => {
  
    // //extract data to update loggedInUser
    // const { cognitoUser, crmUser } = loggedInUser;

    // //find the index of the card in the array to replace
    // let cardIndexToReplace;
    // crmUser.creditCards.forEach((card, index) => {
    //   if (card.uniqueIdentifier === loadCard.uniqueIdentifier) {
    //     cardIndexToReplace = index;
    //   }
    // });

    // //update creditcard in array
    // crmUser.creditCards[cardIndexToReplace] = newCard;
    
    // //update the props for /payment form
    // props.location.state.cardId = newCard.uniqueIdentifier;

    // // update the store for loggedInUser
    // store.dispatch(setLoggedInUser({cognitoUser, crmUser}));

    // // this function is just updating local storage and front end elements, errors handled before reaching updateloggedInUser function
    // // route to successful page
    // history.push({ pathname: '/paymentupdate', state: { updatedCard: true} });

    console.log(JSON.stringify(newCard))
  };

  return (
    <div className={css['payment-container']}>
      <h2 className={css['payment-header']}>{header}</h2>
      <p className={css['payment-text']}>{message}</p>
      
      {clientInstance &&
      <>
        <UpdatePaymentBraintree clientInstance={clientInstance}
                card={loadCard}
                updateLoggedInUser={updateLoggedInUser}
                loadError={loadError} 
                checkout={checkout} />


        {/* <div className={`${css['payment-inputs']}`} >
          <label>Cardholder Name</label>
          <input type='text' value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className={`${css['payment-inputs']}`} >
          <label>Card Number</label>
          <input type='text' value={number} onChange={(e) => setNumber(e.target.value)} />
        </div>
        <div className={`${css['payment-inputs']}`} >
          <label>Card Expiration</label>
          <input type='text' value={exp} onChange={(e) => setExp(e.target.value)} />
          <p>MM/YYYY</p>
        </div>
        <div className={`${css['payment-inputs']}`} >
          <label>CVV</label>
          <input type='text' value={cvv} onChange={(e) => setCvv(e.target.value)} />
        </div>
        <div className={`${css['payment-inputs']}`} >
          <label>Billing Zip/Postal Code</label>
          <input type='text' value={zip} onChange={(e) => setZip(e.target.value)} />
        </div>
        {!checkout && <button
          // onClick ={(e) => {history.push({pathname: '/flow/paymentsuccess' }) }}
          disabled={validationErrors.length > 0}
          onClick ={(e) => {submitCCAdd(e)}}
          className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['payment-add-button']}`}>
            {buttonText}</button>}

        {checkout && <button
          // onClick ={(e) => {history.push({pathname: '/flow/paymentsuccess' }) }}
          disabled={validationErrors.length > 0}
          onClick ={(e) => {submitCCAdd(e)}}
          className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['payment-add-button']}`}>
            {buttonText}</button>}

        {validationErrors.map((message) => {
              return (<div key={`error-${message}`} className={themeCss['error-text']}>
                {message}
              </div>)
        })} */}
      </>}

      {loadError && <div className={themeCss['error-text']}>
              {loadError}
            </div>}
    </div>
  )
}