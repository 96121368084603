import { graphql } from '../api'
import { deleteUserMutation } from '../graphql/Mutations'
import { adminGetAuctionByCodeQuery, adminGetAuctionImagePresignedUrlQuery } from '../graphql/Queries'

// IMPORTANT: deletes Auction User only, does not delete CIDM User
export const deleteAuctionUser = async () => {
  console.log('deleteAuctionUser!')
    try {

      const response = await graphql({query: deleteUserMutation, variables: {userId: '929c614c-57e8-4b57-b124-67d314f53213'}})

      if (response.errors) {
        console.error("User not deleted: ", response.errors[0].message)
        return {error: {msg: response.errors[0].message}}
      }
      else if (!response?.data?.deleteUser?.UserId) {
        console.error("User not deleted: ", response)
        return response
      }
      else {
        const auctionUser = {UserId: response.data.deleteUser.UserId, MyAuctions: []}
        return auctionUser
      }
    }
    catch (e) {
      console.error("error in delete:", e)
      return {error: e}
    }
}


export const adminGetAuctionByCode = async (auctionCode) => {
  console.log('adminGetAuctionByCode!')

  const response = await graphql({query: adminGetAuctionByCodeQuery, variables: {auctionCode}})
  if (!response?.data?.adminGetAuctionByCode) {
    console.log(response)
    return({"error": response})
  }
  else {
    const auction = JSON.parse(JSON.stringify(response.data.adminGetAuctionByCode))

    if (auction.Registrations.length !== auction.EventHubRegistrations.length)
      return({"error": {msg: "Mismatch between Event Hub registrations and Auction regsitrations", response}})

    // we have to do this here because AppSync doesn't allow access to nested data resolvers in the parent pipeline
    auction.Registrations = auction.Registrations.sort((a,b) => a.EventHubRegistrationId > b.EventHubRegistrationId)
    auction.EventHubRegistrations = auction.EventHubRegistrations.sort((a,b) => a.RegistrationId > b.RegistrationId)

    auction.Registrations.forEach((reg, index) =>{
      reg.IsCheckedIn = auction.EventHubRegistrations[index].RegistrationStatus === "CHECKED_IN"
    })

    delete auction.EventHubRegistrations

    return auction
  }
}

export const adminGetAuctionImagePresignedUrl = async (auctionCode, itemId, fileName, contentType) => {
  console.log('adminGetAuctionImagePresignedUrl!')

  const response = await graphql({query: adminGetAuctionImagePresignedUrlQuery, variables: {auctionCode, itemId, fileName, contentType}})
  if (!response?.data?.getPresignedUrlForUpload || !response.data.getPresignedUrlForUpload.PresignedUrl) {
    console.log(response)
    return({"error": response})
  }
  else {
    const presignedUrl = response.data.getPresignedUrlForUpload.PresignedUrl

    return presignedUrl
  }
}
