import { Fragment, useState, useEffect, useContext } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";

import { signOut } from 'authentication/signout';
import { history } from 'services/history.js'
import { isLoading, getLoggedInUser } from "redux/selectors";
import PrivateRoute from 'common/PrivateRoute';
import { ThemeContext } from 'ThemeContext';

import Header from 'common/header/Header';
import Footer from 'common/Footer';
import Loading from 'common/Loading'

import NewAmplifyPostLogin from 'authentication/routes/NewAmplifyPostLogin';
import Login from 'authentication/routes/Login';
import Register from 'authentication/routes/Register';
import RegistrationVerify from 'authentication/routes/RegistrationVerify';
import UsernameExists from 'authentication/routes/UsernameExists';
import ResetPasswordStart from 'authentication/routes/ResetPasswordStart';
import ResetPasswordFinish from 'authentication/routes/ResetPasswordFinish';
import MultipleAccounts from 'authentication/routes/MultipleAccounts';
import MyInformation from 'profile/MyInformation'

import UnderConstruction from './UnderConstruction'
import PrivacyPolicy from 'common/PrivacyPolicy'
import SystemError from 'common/ErrorPage';
import Admin from 'admin/Admin';

import OuterDiv from 'common/OuterDiv'
import BacktoTop from 'common/BacktoTop'
import 'App.css';

import {Grid} from 'css/Grid'
import './sass/global.scss'

function App(props) {
  const theme = useContext(ThemeContext)
  const [ hasMultipleAccounts, setHasMultipleAccounts ] = useState(false)
  const [ isUnderConstruction ] = useState(props.isUnderConstruction || process.env.REACT_APP_UNDER_CONSTRUCTION || 'NO')
  const [ authenticatedUser, setAuthenticatedUser ] = useState(null)
  const loading = useSelector(isLoading);
  const loggedInUser = useSelector(getLoggedInUser);

  useEffect(() => {
    async function checkAuth() {
      // handle case of user closing browser then coming back the next day
      if (localStorage.getItem('sessionEnds') && localStorage.getItem('sessionEnds') < (new Date()).getTime()) {
        await signOut();
      }
      else {
        setAuthenticatedUser(loggedInUser)
        theme.themeAnalytics.analyticsPageLoad()
      }
    }
    checkAuth()
  }, [loggedInUser, theme.themeAnalytics])

  const genericSystemError = (
    <Fragment>
      We're unable to process your request at the moment.
    </Fragment>
  )

  return (
    <div >

      <Grid />
        <Router history={history}>
          <OuterDiv name="top">
              {(!theme.hideHeader || !theme.hideHeader()) && <Header
                authenticatedUser={authenticatedUser}
                hasMultipleAccounts={hasMultipleAccounts}
                isUnderConstruction={isUnderConstruction}
              />}
            <Switch>

              <Route exact path='/underconstruction' render={props => <UnderConstruction/>} key='/underconstruction' /> {/* to show preview and test */}
              {isUnderConstruction === 'YES' && <Route exact={true} path='/' render={props => <UnderConstruction />} key='/underconstructionhome' />}
              {isUnderConstruction === 'YES' && <Route exact path='/signin' render={props => <UnderConstruction  />} key='/underconstructionsignin' />}
              {isUnderConstruction === 'YES' && <Route exact path='/registration' render={props => <UnderConstruction key='/underconstructionregstration' />} />}

              <PrivateRoute exact={true} path='/profile' component={MyInformation} key='/profile' />

              {theme.routes}

              <Route exact path='/signin' render={props => <Login {...props} />}  key='/signin'  />
              <Route exact={true} path='/postlogin' component={NewAmplifyPostLogin} key='/postlogin' />,
              <Route exact path='/registration' render={props => <Register {...props} />}  key='/registration' />
              <Route exact path='/registerVerify' render={props => <RegistrationVerify {...props}  key='/registerVerify' />} />
              <Route exact path='/usernameexists' render={props => <UsernameExists />}  key='/usernameexists' />
              <Route exact path='/resetpasswordstart' render={props => <ResetPasswordStart />}  key='/resetpasswordstart' />
              <Route exact path='/resetpasswordfinish' render={props => <ResetPasswordFinish />}  key='/resetpasswordfinish' />
              <Route exact path='/multipleaccounts' render={props => <MultipleAccounts hasMultipleAccounts={setHasMultipleAccounts}  key='/multipleaccounts' />} />
              <Route exact path='/privacypolicy' render={props => <PrivacyPolicy />}  key='/privacypolicy' />
              <Route exact path='/systemerror' render={props => (
                <SystemError displayCallButton={true} > {genericSystemError} </SystemError>
              )} key='/systemerror' />
              <Route exact path='/error' render={props => (
                <SystemError> {genericSystemError} </SystemError>
              )} key='/error'/>

              {/* Admin subroutes */}
              <PrivateRoute path='/admin/*' component={Admin} data={{parentHistory:history}} key='/admin/*' />
              <Redirect exact path="/admin/linkusertocrm/success" to="/admin/linkusertocrm" key='/admin/linkusertocrm' />
            </Switch>

          </OuterDiv>
        </Router>

        {(!theme.hideFooter || !theme.hideFooter()) &&
          <>
            <BacktoTop />
            <Footer />
          </>}

      <Loading isLoading={loading} />
    </div>
  )
}

export default App;
