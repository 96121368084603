import { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import { useQuery, useQueryClient } from "@tanstack/react-query"
// import requireContext from 'require-context.macro'

import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { getAuctionStatus } from '../util/dateString'
import store from 'redux/store'
import { setLiveAuctionCode } from '../redux/actions'

import { getAuctionByCode, updateUserRegistration, registerUserForAuction } from '../api'
import { urlQueryParser } from '../util/urlQueryParser'
import { isAttending } from '../util/userUtils'
import { updateLiveBid } from '../auctionUtils'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/auctions.module.scss'
import auctionCardCss from './sass/auctionscards.module.scss'

import AuctionCountdown from './AuctionCountdown'
import ItemCard from '../items/ItemCard'
import ConfirmBidModal from '../components/ConfirmBidModal'

import TextMessageOff from './TextMessageOff'
import TextMessageOn from './TextMessageOn'
import AuctionQrCard from './AuctionQrCard'

import { getAuctionUser, getBidSubscriptionResponse } from '../redux/selectors'
import BidSuccessNotification from '../components/BidSuccessNotification'
import AuctionStatus from '../components/AuctionStatus'

//temporary fix for images
// const images = requireContext('./images', true)
// const imageList = images.keys().map(image => images(image))

export default function Auction(props) {
  //destructuring auction code from url
  //called id in url query for that convention, but using auction code in aws to declutter url from being massive from aws UUID for auction ID to link tables
  const { auctionId: auctionCode } = urlQueryParser(props.location.search)

  const [date, setDate] = useState(new Date())
  const [auctionState, setAuctionState] = useState()
  const [textAlertNumber, setTextAlertNumber] = useState()
  const [isCheckedIn, setIsCheckedIn] = useState(false)

  //bid and notifications
  const [showBidModal, setShowBidModal] = useState()
  const [bidItem, setBidItem] = useState()
  const [bidAmount, setBidAmount] = useState()
  const [bidResponse, setBidResponse] = useState()
  const [myItems, setMyItems] = useState([])
  const [successItem, setSuccessItem] = useState()
  const successBannerTime = 7

  // for test / demo purposes
  const [forcedEnd, setForcedEnd] = useState(false)

  // let auctionUser = useSelector(getAuctionUser)
  const auctionUser = useSelector(getAuctionUser);
  const currentUserId = auctionUser?.UserId
  const userPhone = auctionUser.ContactPhone

  const bidSubscriptionResponse = useSelector(getBidSubscriptionResponse);

  //to refresh query on changes
  const queryClient = useQueryClient()

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["auctionDetail", auctionCode],
    queryFn: async () => {
      console.log('hello')
      return await getAuctionByCode(auctionCode)
    },
    staleTime: 0
  })

  useEffect(() => {
    if (props.location.outbidNotification) {
      setShowBidModal(props.location.outbidNotification.showBidModal)
      setBidItem(props.location.outbidNotification.outbidItem)
      setBidAmount(props.location.outbidNotification.bidAmount)
    }

  }, [props.location.outbidNotification])

  // redux liveAuctionCode is consumed by <OutBidNotification> set in the theme header (and could be by other global widgets)
  // I think it's best to only set auctionCode and force the widget to get a fresh version of the auction (since some things can change)
  // system assumes only one live auction at a time, which I think is a fair assumption to not add tons of code to handle multiple live auctions
  useEffect(() => {
    if (auctionState === 'started') {
      store.dispatch(setLiveAuctionCode(auctionCode))
    }
    else {
      store.dispatch(setLiveAuctionCode())
    }
   }, [auctionState, auctionCode])

  //use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])

  // to update list of my items
  useEffect(() => {
    const filteredItems = auctionDetail?.Items.filter(item => item.CurrentHighBidder === currentUserId).map(item => item.ItemId)
    setMyItems(filteredItems)
  }, [auctionDetail, currentUserId])
  
  //is the user attending
  const userAttending = isAttending(auctionDetail?.AuctionId, auctionUser)

  //checking registration for checked in status and text alert status
  useEffect(() => {
    if (!auctionDetail || !auctionUser) return

    const myRegistration = auctionUser.MyAuctions.filter(registration => {
      return registration.Auction.AuctionId === auctionDetail.AuctionId
    })[0]


    if (myRegistration) {
      setTextAlertNumber(myRegistration.TextAlertNumber)
      setIsCheckedIn(myRegistration.IsCheckedIn)
    }
    
  }, [auctionDetail, auctionUser])

  const updateStatus = (status) => {
    setAuctionState(status)
  }

  const updateBidModalAmount = (itemBidAmount) => {
    setBidAmount(itemBidAmount)
  }

  const submitBid = async (item, bidAmount) => {
    showLoading(true)
  
    let updatedItem = {}
  
    const bidFields = {
      itemId: item.ItemId,
      bidAmount,
    }
    try {
      const response = await updateLiveBid(bidFields)
      setBidResponse(response)

      if (response.error) {
        console.log('errored', response)
      } else {
        console.log('passed', response)
        updatedItem = {...item, ...response, timeOut: new Date(date.getTime() + (successBannerTime * 1000))}
        setSuccessItem(updatedItem)
        queryClient.invalidateQueries(["auctionDetail"])
      }
    }
    catch(e) {
      setBidResponse(e)
    }
    //reset bid response for downstream checks on bid response
    showLoading(false)
  }

  //use effect if bidSubscriptionResponse changes
  useEffect(() => {

    //if item bid on is in auction, update the auction details
    if (auctionDetail && bidSubscriptionResponse?.ItemId) {

      const isInAuction = (item) => item.ItemId === bidSubscriptionResponse.ItemId
      //if bid response item id is in auction items array
      if (auctionDetail.Items.findIndex(isInAuction) > -1) {
        //invalid query to repull
        queryClient.invalidateQueries(["auctionDetail"])
      }
    }

  }, [bidSubscriptionResponse, auctionDetail, queryClient])

  const simCheckIn = async () => {
    const newStatus = !isCheckedIn

    try {
      const updateCheckedInStatusResponse = await updateUserRegistration(auctionDetail, auctionUser, {isCheckedIn: newStatus})
      console.log('hello', {updateCheckedInStatusResponse})

      if (isCheckedIn) setIsCheckedIn(false)
      else setIsCheckedIn(true)

      return {success:true, updateCheckedInStatusResponse}
    }
    catch (e) {
      console.log(e)
      return e
    }
  }

  const registerForAuction = async (auction) => {
    showLoading(true)
    const signUpResponse = await registerUserForAuction(auction.AuctionCode, auctionUser)
    showLoading(false)
  }

  // maybe provide a link for if no auction is loaded to reroute to load auctions - 404 something
  if (!auctionDetail) return (<></>)
  
  //temporary fix for banner image 
  // const banner = auctionDetail.BannerImageName
  // const bannerImage = imageList.find(imageLink => imageLink.includes(banner.substring(0, banner.indexOf('.'))))

  return (
    <div className={css['auction-container']}>
      <AuctionStatus auction={auctionDetail} updateStatus={updateStatus} forcedEnd={forcedEnd} />

      {showBidModal && <ConfirmBidModal submitFn={submitBid} item={bidItem} bidAmount={bidAmount}
        showBidModal={showBidModal} setShowBidModal={setShowBidModal} bidResponse={bidResponse} setBidResponse={setBidResponse} />}

        <BidSuccessNotification successItem={successItem} />

{/*      <NotificationContainer auctionDetail={auctionDetail} currentUserId={currentUserId} myItems={myItems}
        successItems={successItems} subResponse={subResponse} setSubResponse={setSubResponse}
        setBidItem={setBidItem} setBidAmount={setBidAmount} setShowBidModal={setShowBidModal} />
*/}
      <h2 id="landingPageTitle">
        {auctionDetail.Title}
      </h2>
      
      <div className={themeCss['blue-background']}>
        {/* <img className={css['auction-banner']} src={`${bannerImage}`} alt={`${auctionDetail.Title} banner`} /> */}
        
        {userAttending && <div>
          <AuctionCountdown auctionDetail={auctionDetail} auctionState={auctionState} isCheckedIn={isCheckedIn} />
        </div>}

        {!userAttending && <>
            <p className={css['auction-description-unreg']}>{auctionDetail.Description}</p>
              <button className={`${themeCss['FilledYellow']} ${themeCss['button']}`}
                  onClick={() => registerForAuction(auctionDetail)}
                  disabled={auctionState === 'ended'}
                >Sign Up For Auction</button>
          </>
          }

        <AuctionQrCard auction={auctionDetail} isCheckedIn={isCheckedIn} />

        {/*<p>Current Text Alert Number (demonstration): {textAlertNumber || "none set"} declineTexts: {declineTexts}</p>*/}

        {!textAlertNumber && userAttending && <TextMessageOff textAlertNumber={textAlertNumber} auctionUser={auctionUser} auction={auctionDetail} />}

        {(auctionState === 'ended' && myItems?.length > 0) && 
          <div className={themeCss['box-top-yellow-accent-wide']} >
            <p className={auctionCardCss['auction-box-info-heading']}>Thank you for participating!</p>
            <p className={auctionCardCss['auction-box-info-text']}>Below are the items you have won.</p>
            <button
                  onClick ={(e) => {history.push({
                    pathname: '/checkout',
                    search: `?auctionId=${auctionDetail.AuctionCode}`,
                    // auctionDetail, myItems
                  })}}
                  className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['button-to-checkout']}`}
                >Proceed to Checkout</button>
          </div>
        }
        {userAttending && auctionDetail.Items && auctionDetail.Items.map((item) => {
          return <ItemCard key={item.ItemCode} item={item} auction={auctionDetail} auctionState={auctionState} 
            currentUserId={currentUserId} isCheckedIn={isCheckedIn} userAttending={userAttending}
            setBidItem={setBidItem} setShowBidModal={setShowBidModal}
            // bidAmount={bidAmount} setBidAmount={setBidAmount} 
            updateBidModalAmount={updateBidModalAmount} />
        })}

        {/* for non registered viewers of the auction: */}
        {!userAttending && auctionDetail.Items && auctionDetail.Items.map((item) => {
          return <ItemCard key={item.ItemCode} item={item} auction={auctionDetail} auctionState={'before'} 
            currentUserId={currentUserId} isCheckedIn={isCheckedIn} userAttending={userAttending}
            setBidItem={setBidItem} setShowBidModal={setShowBidModal}
            // bidAmount={bidAmount} setBidAmount={setBidAmount}
            updateBidModalAmount={updateBidModalAmount} />
        })}

        {(auctionState === 'ended' && myItems?.length > 0) && 
          <div className={themeCss['box-top-yellow-accent-wide']} >
            <p className={auctionCardCss['auction-box-info-heading']}>Thank you for participating!</p>
            <button
                  onClick ={(e) => {history.push({
                    pathname: '/checkout',
                    search: `?auctionId=${auctionDetail.AuctionCode}`,
                    auctionDetail, myItems
                  })}}
                  className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['button-to-checkout']}`}
                >Proceed to Checkout</button>
          </div>
        }

        {textAlertNumber === 'declined' && userAttending && <TextMessageOff textAlertNumber={textAlertNumber} auctionUser={auctionUser} auction={auctionDetail} />}
        {textAlertNumber && userAttending && textAlertNumber !== 'declined' && <TextMessageOn auctionUser={auctionUser} auction={auctionDetail} />}

        {userAttending && <p className={css['auction-description']}>{auctionDetail.Description}</p>}
        <p className={css['auction-description']}>
          <br />
          MAILING REMINDER:
          <br />
          Winning bidder incurs all costs for shipping and handling.
          <br /> <br />
          Large and heavy items cannot be mailed.
          <br /> <br />
          Happy browsing and bidding! Thanks!
        </p>
        {userAttending && <>
          <button className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['sim-button']}`} 
            onClick={()=> simCheckIn()}>{isCheckedIn ? 'Simulate Check Out' : 'Simulate Check In'}</button>
          <button className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['sim-button']}`} 
            onClick={()=> setForcedEnd(true)}>Simulate Auction End</button>
        </>}
      </div>
    </div>
  )
}