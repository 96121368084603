import requireContext from 'require-context.macro'

import { formatMoneyCents } from 'services/util'

import css from './sass/checkout.module.scss'

//temporary fix for images
const images = requireContext('../items/images', true)
const imageList = images.keys().map(image => images(image))

export default function CheckoutItem( {item} ) {
  
  //temporary fix for images
  let itemImage = null
  if (item) {
    const image = item.ImageFilename
    itemImage = imageList.find(imageLink => imageLink.includes(image?.substr(0, image.indexOf('.'))))
  }
  // need to test with longer titles
  
  if (!item) return <></>
  

  console.log(item)
  return (
    <div className={css['checkout-item-container']}>
      <div className={css['checkout-item-container-left']}>
        <img className={css['checkout-item-img']} src={itemImage} alt={item.Title} />
        <h5 className={css['checkout-item-title']}>{item.Title}</h5>
      </div>
      <p className={css['checkout-item-price']}>{`$${formatMoneyCents(item?.CurrentBid)}`}</p>
    </div>
  )
}




