import { Link } from 'react-router-dom'

import css from './sass/endedauction.module.scss'

export default function EndedAuctionAttendeeRow( {attendee, auctionCode, selectedAttendees, toggleCheckbox} ) {
  // console.log(attendee)

  const formatName = (attendee) => {
    return `${attendee.CurrentHighBidderInfo.FirstName} ${attendee.CurrentHighBidderInfo.LastName}`
  }


  return (
    <tr className={css['ended-auction-table-data-row']}>
      <td>
        <input type='checkbox'
          checked={selectedAttendees.indexOf(attendee.CurrentHighBidder) > -1}
          onChange={() => toggleCheckbox(attendee.CurrentHighBidder)} 
          />
      </td>
      <td>{formatName(attendee)}</td>
      <td>{attendee.TotalDue}</td>
      <td>{attendee.TotalPaid}</td>
      <td>{attendee.Items.length}</td>
      <td>{'alt'}</td>
      <td>
        <Link className={css['card-title']} to={{ pathname: '/admin/endedauction/attendee', search: `?auctionId=${auctionCode}&attendeeId=${attendee.CurrentHighBidder}`}}>
            Manage Checkout
        </Link>
      </td>
    </tr>
  )
}