import { Link } from 'react-router-dom'

import css from './sass/endedauction.module.scss'

export default function EndedAuctionAttendeeItemRow( {item} ) {

  // console.log(item)

  // const formatName = (attendee) => {
  //   return `${attendee.CurrentHighBidderInfo.FirstName} ${attendee.CurrentHighBidderInfo.LastName}`
  // }



  return (
    <tr className={css['ended-auction-table-data-row']}>
      <td>{item.Title}</td>
      <td>{item.Paid ? '$0' : `$${item.CurrentBid}`}</td>
      <td>{item.Paid ? `$${item.CurrentBid}` : '$0'}</td>
      <td>NO</td>
      <td>{item.Paid ? 'YES' : 'NO'}</td>
      <td>
        {/* <Link className={css['card-title']} to={{ pathname: '/admin/endedauction/attendee', search: `?auctionId=${auctionCode}&attendeeId=${attendee.CurrentHighBidder}`}}> */}
            Manage Checkout
        {/* </Link> */}
      </td>
    </tr>
  )
}